import React, { forwardRef,useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { ApiError } from "../../../api/Api";
import {
checkIfClustered, getChunkMetadata, getUploadProgress,   uploadFile, UploadProgress
} from "../../../api/FlowFiles";
import { getFlowDataFromFirestore } from "../../../api/manual-gate/LoadData";
import { Alert, AlertInfo } from "../../../components/Alerts";
import { Progress, ProgressButton } from '../../../components/Buttons';
import { CollapseIcon } from '../../../components/Collapsibles';
import {FileDisplay, FlowFileInfo,updateFileInfoInList} from '../../../components/Files';
import type { FlowData } from "../../../components/FlowData";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import type { ClusterInfo } from "../Clusters";
import type { FlowPlotItem } from "../plots/FlowPlotItem";
import type { CompParams, TransParams } from "../PlotSettings";
import { FlowFileStorageSelector } from "./FlowFilesStorage";
import { UploadFlowFile } from "./UploadFlowFile";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


interface LoadFlowFileProps {
  fileInfo: FlowFileInfo | undefined;
  setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>;
  setFlowData: React.Dispatch<React.SetStateAction<FlowData>>;
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  setUmapFlowPlotItem: React.Dispatch<React.SetStateAction<FlowPlotItem>>;
  numCells: number;
  compParams: CompParams;
  transParams: TransParams;
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>;
  fileInfoStorageList: Array<FlowFileInfo>;
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
  fileInfoDisplayList: Array<FlowFileInfo>;
  setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
  setDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoadFlowFile = forwardRef(
  function LoadFlowFile(
  { fileInfo, setFileInfo, setFlowData, flowPlotList, setFlowPlotList,
    setUmapFlowPlotItem, numCells, compParams, transParams, setClusterInfo,
    fileInfoStorageList, setFileInfoStorageList, fileInfoDisplayList,
      setFileInfoDisplayList, setDataIsLoading
    }: LoadFlowFileProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>
) {

  const [isLoading, setIsLoading] = useState(false);
  const [showUploadFlowFile, setShowUploadFlowFile] = useState(true);
  const [progress, setProgress] = useState(new Progress(0));
  const [alertInfo, setAlertInfo] = useState(new AlertInfo("", false, "danger"));
  const [fileInfoLocalList, setFileInfoLocalList] = useState<Array<FlowFileInfo>>([]);

  return (
    <>
      {showUploadFlowFile &&
        <Card>
          <Card.Header as="h5">
            Load FCS Files
            <CollapseIcon
                showComponent={showUploadFlowFile} setShowComponent={setShowUploadFlowFile}
            />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={(event) => loadFlowData(
              event, fileInfo, setFileInfo, numCells, setFlowData, flowPlotList, setFlowPlotList,
              setUmapFlowPlotItem, compParams, transParams, setClusterInfo, setIsLoading,
              setDataIsLoading, setProgress, setAlertInfo,
              fileInfoStorageList, setFileInfoStorageList,
              fileInfoDisplayList, setFileInfoDisplayList,
              ref as React.MutableRefObject<Array<FlowPlotItem>>)}
            >
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <label htmlFor="upload-flow-file-local-selector" className="form-label">
                  Select a file from your computer:
                </label>
                <Row>
                  <UploadFlowFile
                    setFileInfo={setFileInfo}
                    setFileInfoDisplayList={setFileInfoDisplayList}
                    setFileInfoLocalList={setFileInfoLocalList}
                  />
                </Row>
                <label htmlFor="upload-flow-file-storage-selector" className="form-label">
                  Or select a file from storage:
                </label>
                <Row>
                  <FlowFileStorageSelector
                    fileInfo={fileInfo} setFileInfo={setFileInfo}
                    fileInfoStorageList={fileInfoStorageList}
                    setFileInfoDisplayList={setFileInfoDisplayList}
                    id="upload-flow-file-storage-selector"
                  />
                </Row>
                <Row>
                  <FileDisplay fileInfo={fileInfo} fileInfoList={fileInfoDisplayList}
                    setFileInfo={setFileInfo} />
                </Row>
                <ProgressButton buttonTitle="Get Data" isLoading={isLoading} progress={progress}
                  offsetButton={1} colSizeButton={10} />
                <Row>
                  <Alert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
                </Row>
              </Form.Group>
            </Form >
          </Card.Body >
        </Card >
      }
      {
        !showUploadFlowFile &&
        <Card>
          <Card.Header as="h5" className="collapsed">
            Load FCS Files
            <CollapseIcon
                showComponent={showUploadFlowFile}
                setShowComponent={setShowUploadFlowFile}
            />
          </Card.Header>
        </Card>
      }
    </>
  );
});


/**
 * Load data from an *.fcs file on the backend.
 *
 * This function is called when the file is changed in UploadFlowFile.
 * A new FlowData object will be created.
 *
 * @param {React.FormEvent<HTMLFormElement>} event - a submit event from clicking a button.
*/
async function loadFlowData(
  event: React.FormEvent<HTMLFormElement>,
  fileInfo: FlowFileInfo | undefined,
  setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>,
  numCells: number,
  setFlowData: React.Dispatch<React.SetStateAction<FlowData>>,
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  setUmapFlowPlotItem: React.Dispatch<React.SetStateAction<FlowPlotItem>>,
  compParams: CompParams,
  transParams: TransParams,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setProgress: React.Dispatch<React.SetStateAction<Progress>>,
  setAlertInfo: React.Dispatch<React.SetStateAction<AlertInfo>>,
  fileInfoStorageList: Array<FlowFileInfo>,
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
  fileInfoDisplayList: Array<FlowFileInfo>,
  setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): Promise<void> {

  event.preventDefault();
  if (fileInfo === undefined) {
    logger.error(
      "loadDataFromFile",
      "File is undefined"
    )();
  } else if (fileInfo.location === "storage") {
    loadFlowDataFromStorage(
      fileInfo, setFileInfo,
      numCells, setFlowData, flowPlotList, setFlowPlotList,
      setUmapFlowPlotItem, compParams, transParams, setClusterInfo, setIsLoading,
      setDataIsLoading, setProgress, setAlertInfo, fileInfoStorageList,
      setFileInfoStorageList, fileInfoDisplayList, setFileInfoDisplayList, flowPlotListRef
    );
  } else {
    loadFlowDataFromFile(
      fileInfo, setFileInfo, numCells, setFlowData, flowPlotList, setFlowPlotList,
      setUmapFlowPlotItem, compParams, transParams, setClusterInfo, setIsLoading,
      setDataIsLoading, setProgress, setAlertInfo, fileInfoStorageList, setFileInfoStorageList,
      fileInfoDisplayList, setFileInfoDisplayList, flowPlotListRef
    );
  }
}


/**
 * Load data from an *.fcs file on the backend.
 *
 * This function is called when the file is changed in UploadFlowFile.
 * A new FlowData object will be created.
 *
 * @param fileInfo - TODO.
*/
async function loadFlowDataFromStorage(
  fileInfo: FlowFileInfo,
  setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>,
  numCells: number,
  setFlowData: React.Dispatch<React.SetStateAction<FlowData>>,
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  setUmapFlowPlotItem: React.Dispatch<React.SetStateAction<FlowPlotItem>>,
  compParams: CompParams,
  transParams: TransParams,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setProgress: React.Dispatch<React.SetStateAction<Progress>>,
  setAlertInfo: React.Dispatch<React.SetStateAction<AlertInfo>>,
  fileInfoStorageList: Array<FlowFileInfo>,
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
  fileInfoDisplayList: Array<FlowFileInfo>,
  setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): Promise<void> {

  setIsLoading(true);
  setDataIsLoading(true);
  setProgress(new Progress(5, 5, 100));
  try {
    logger.info(
      "loadFlowDataFromStorage",
      "files",
      fileInfo
    )();
    let isClustered = fileInfo.isClustered;
    let newFileInfo = new FlowFileInfo({...fileInfo});
    if (!isClustered) {
      isClustered = await checkIfClustered(fileInfo.name);
      if (isClustered !== fileInfo.isClustered) {
        newFileInfo = new FlowFileInfo({
          ...fileInfo,
          isClustered: isClustered
        });
        setFileInfo(newFileInfo);
        updateFileInfoInList(newFileInfo, fileInfoStorageList, setFileInfoStorageList);
        updateFileInfoInList(newFileInfo, fileInfoDisplayList, setFileInfoDisplayList);
      }
    }
    if (!isClustered) {
      setAlertInfo(new AlertInfo(
        "Data has not been clustered yet. It is currently being processed, see the Files tab.",
        true,
        "warning"
      ));
    }
    if (fileInfo.totalEvents === undefined || fileInfo.numEventsPerChunk === undefined 
      || fileInfo.numEventsPerChunkHTTP === undefined) {
        newFileInfo = await getChunkMetadata(fileInfo);
        setFileInfo(newFileInfo);
        updateFileInfoInList(newFileInfo, fileInfoStorageList, setFileInfoStorageList);
        updateFileInfoInList(newFileInfo, fileInfoDisplayList, setFileInfoDisplayList);
    }
    await getFlowDataFromFirestore(
      newFileInfo, numCells, setFlowData,
      flowPlotList, setFlowPlotList, setUmapFlowPlotItem,
      compParams, transParams, setClusterInfo, flowPlotListRef,
      new Progress(5, 5, 100), setProgress, setDataIsLoading
    );

  } catch (error) {
    if (error instanceof ApiError) {
      setAlertInfo(new AlertInfo(error.message, true, "danger"));
    }
  } finally {
    await new Promise(r => setTimeout(r, 800));
    setIsLoading(false);
  }
}



/**
 * Load data from an *.fcs file on the backend.
 *
 * This function is called when the file is changed in UploadFlowFile.
 * A new FlowData object will be created.
*/
async function loadFlowDataFromFile(
  fileInfo: FlowFileInfo,
  setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>,
  numCells: number,
  setFlowData: React.Dispatch<React.SetStateAction<FlowData>>,
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  setUmapFlowPlotItem: React.Dispatch<React.SetStateAction<FlowPlotItem>>,
  compParams: CompParams,
  transParams: TransParams,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setProgress: React.Dispatch<React.SetStateAction<Progress>>,
  setAlertInfo: React.Dispatch<React.SetStateAction<AlertInfo>>,
  fileInfoStorageList: Array<FlowFileInfo>,
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
  fileInfoDisplayList: Array<FlowFileInfo>,
  setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): Promise<void> {

  logger.info(
    "loadFlowDataFromFile",
    "files",
    "fileInfoStorageList",
    fileInfoStorageList
  )();
  logger.info(
    "loadDataFromFile",
    "api",
    "flowPlotListRef",
    flowPlotListRef!.current!
  )();
  setIsLoading(true);
  setDataIsLoading(true);
  setProgress(new Progress(5, 5, 100));
  try {
    let progress = new UploadProgress(5, 5, 15);
    const firestoreDocument = `flow_data/${fileInfo.name}`;
    const firestoreField = "name";
    await uploadFile(fileInfo.file!, progress, setProgress, firestoreDocument, firestoreField);
    while (progress.value < 75) {
      progress = await getUploadProgress(
        fileInfo.name, new Progress(15, 15, 75)
      );
      await new Promise(r => setTimeout(r, 5000));
      setProgress(progress);
    }
    let newFileInfo = await getChunkMetadata(fileInfo);

    await getFlowDataFromFirestore(
      newFileInfo, numCells, setFlowData,
      flowPlotList, setFlowPlotList, setUmapFlowPlotItem,
      compParams, transParams, setClusterInfo, flowPlotListRef,
      new Progress(75, 75, 100), setProgress, setDataIsLoading
    );
    let isClustered = fileInfo.isClustered;
    if (isClustered === undefined) {
      isClustered = await checkIfClustered(fileInfo.name);
    }
    newFileInfo = new FlowFileInfo({
      ...newFileInfo,
      name: newFileInfo.name,
      location: "storage",
      type: "",
      progress: {
        firestore: progress.firestore,
        cluster: progress.cluster
      },
      isClustered: isClustered
    });
    setFileInfo(newFileInfo);
    updateFileInfoInList(newFileInfo, fileInfoStorageList, setFileInfoStorageList);
    updateFileInfoInList(newFileInfo, fileInfoDisplayList, setFileInfoDisplayList);

    if (!isClustered) {
      setAlertInfo(new AlertInfo(
        "Data has not been clustered yet. It is currently being processed, see the Files tab.",
        true,
        "warning"
      ));
    }
  } catch (error) {
    if (error instanceof ApiError) {
      setAlertInfo(new AlertInfo(error.message, true, "danger"));
    }
  } finally {
    await new Promise(r => setTimeout(r, 800));
    setIsLoading(false);
  }
}


export class FlowData {
  fileName = "";
  channels: Array<string> = [];
  maxNumCells = 1000;
  numCells = 1000;
  numEventsPerChunk = 1000;
  totalChunks = 1;
  events: Record<string, Array<number>> = {};
  layers: Record<string, Array<number>> = {};
}
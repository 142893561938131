export const LOG_FILTERS: Array<string> = [];
export const LOG_LEVEL = "info";

const LEVELS: Record<string, number> = {
  debug: 10,
  info: 20,
  warn: 30,
  error: 40
}

export type LogFn = (message?: any, ...optionalParams: Array<any>) => (...args: Array<any>) => void;


export interface Logger {
  level: string;
  levelFilters: Array<string>;
  shouldLog: (level: string, levelFilter?: string) => boolean;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
}

/**
 * Logger which outputs to the browser console.
 *
 * @class ConsoleLogger
 */
export class ConsoleLogger implements Logger {

  level: string;
  levelFilters: Array<string>;

  constructor(level = "debug", levelFilters: Array<string> = []) {
    this.level = level;
    this.levelFilters = levelFilters;
  }

  shouldLog(level: string, levelFilter?: string): boolean {
    if (LEVELS[level] >= LEVELS[this.level]) {
      if (this.levelFilters.length > 0) {
        if (levelFilter === undefined) {
          return false;
        } else if (this.levelFilters.includes(levelFilter)) {
          return true;
        } else {
          return false;
        }
      }
      else {
        return true;
      }
    } else {
      return false;
    }
  }

  info(
    functionName?: string, levelFilter?: string, message?: any, ...optionalParams: Array<any>
  ): (...args: Array<any>) => void {
    if (this.shouldLog("info", levelFilter)) {
      const backgroundColor = "lightblue";
      const background = `background: ${backgroundColor}; color: #444; padding: 3px; border-radius: 5px;`;
      const backgroundInherit = "background: inherit;";
      const coreMessage = [
        "%c%s%c%s%s%c%s%c%s",
        background, "Function:", backgroundInherit, ` ${functionName}`, "\n",
        background, "Info:", backgroundInherit, " ", message
      ]
      return console.log.bind(
        console, ...[...coreMessage, ...optionalParams]
      );
    } else {
      return () => undefined;
    }
  };

  warn(
    functionName?: string, levelFilter?: string, message?: any, ...optionalParams: Array<any>
  ): (...args: Array<any>) => void {
    if (this.shouldLog("warn", levelFilter)) {
      const backgroundColor = "#fa853c";
      const background = `background: ${backgroundColor}; color: #444; padding: 3px; border-radius: 5px;`;
      const backgroundInherit = "background: inherit;";
      const coreMessage = [
        "%c%s%c%s%s%c%s%c%s",
        background, "Function:", backgroundInherit, ` ${functionName}`, "\n",
        background, "Warning:", backgroundInherit, " ", message
      ]
      return console.warn.bind(
        console, ...[...coreMessage, ...optionalParams]
      );
    } 
      return () => undefined;
    
  };

  error(
    functionName?: string, message?: any, ...optionalParams: Array<any>
  ): (...args: Array<any>) => void {
    const backgroundColor = "#fa3c3c";
    const background = `background: ${backgroundColor}; color: #444; padding: 3px; border-radius: 5px;`;
    const backgroundInherit = "background: inherit;";
    const coreMessage = [
      "%c%s%c%s%s%c%s%c%s",
      background, "Function:", backgroundInherit, ` ${  functionName}`, "\n",
      background, "Error:", backgroundInherit, " ", message
    ]
    return console.error.bind(
      console, ...[...coreMessage, ...optionalParams]
    );
  }
}

export class FlowPlotLogger extends ConsoleLogger {
  override info(
    functionName?: string, levelFilter?: string, flowPlotId?: string, message?: any,
    ...optionalParams: Array<any>
  ): (...args: Array<any>) => void {
    if (this.shouldLog("info", levelFilter)) {
      const backgroundColor = "lightblue";
      const background = `background: ${backgroundColor}; color: #444; padding: 3px; border-radius: 5px;`;
      const backgroundInherit = "background: inherit;";
      const coreMessage = [
        "%c%s%c%s%s%c%s%c%s%s%c%s%c%s",
        background, "Function:", backgroundInherit, ` ${functionName}`, "\n",
        background, "FlowPlotItem:", backgroundInherit, ` ${flowPlotId}`, "\n",
        background, "Info:", backgroundInherit, " ", message
      ]
      return console.log.bind(
        console, ...[...coreMessage, ...optionalParams]
      );
    } else {
      return () => undefined;
    }
  };

  override warn(
    functionName?: string, flowPlotId?: string, levelFilter?: string, message?: any,
    ...optionalParams: Array<any>
  ): (...args: Array<any>) => void {
    if (this.shouldLog("warn", levelFilter)) {
      const backgroundColor = "#fa853c";
      const background = `background: ${backgroundColor}; color: #444; padding: 3px; border-radius: 5px;`;
      const backgroundInherit = "background: inherit;";
      const coreMessage = [
        "%c%s%c%s%s%c%s%c%s%s%c%s%c%s",
        background, "Function:", backgroundInherit, ` ${functionName}`, "\n",
        background, "FlowPlotItem:", backgroundInherit, ` ${flowPlotId}`, "\n",
        background, "Warning:", backgroundInherit, " ", message
      ]
      return console.warn.bind(
        console, ...[...coreMessage, ...optionalParams]
      );
    } else {
      return () => undefined;
    }
  };

  override error(
    functionName?: string, flowPlotId?: string, message?: any, ...optionalParams: Array<any>
  ): (...args: Array<any>) => void {
    const backgroundColor = "#fa3c3c";
    const background = `background: ${backgroundColor}; color: #444; padding: 3px; border-radius: 5px;`;
    const backgroundInherit = "background: inherit;";
    const coreMessage = [
      "%c%s%c%s%s%c%s%c%s%s%c%s%c%s",
      background, "Function:", backgroundInherit, ` ${functionName}`, "\n",
      background, "FlowPlotItem:", backgroundInherit, ` ${flowPlotId}`, "\n",
      background, "Error:", backgroundInherit, " ", message
    ]
    return console.error.bind(
      console, ...[...coreMessage, ...optionalParams]
    );
  }
}

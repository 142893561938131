import type { ResponseType } from "axios";
import axios from "axios";

import { auth } from "../firebase/firebase.utils";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../utils/Logger";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export class AuthenticationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AuthenticationError";
  }
}

export class ApiError extends Error {

  status: number | null;

  constructor(message: string, status: number | null = null) {
    super(message);
    this.status = status;
  }
}

export async function apiPost(
  urlSuffix: string,
  formData: FormData,
  responseType: ResponseType = "json"
): Promise<any> {

  if (auth.currentUser == null) {
    throw new AuthenticationError("User is not logged in, cannot make request to backend.");
  } else {
    const token = await auth.currentUser.getIdToken();
    try {
      const url = `${REACT_APP_API_URL}${urlSuffix}`;
      const response = await axios({
        url: url,
        method: "POST",
        data: formData,
        responseType: responseType,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        }
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        logger.error(
          "apiPost",
          "api",
          error.response.data,
          `status: ${error.response.status}`
        )();
        throw new ApiError(
          error.response.data,
          error.response.status
        );
      } else if (error.request) {
        logger.error(
          "apiPost",
          "api",
          error.request
        )();
        throw new ApiError(
          "No response received from the server",
          null
        );
      } else {
        logger.error(
          "apiPost",
          "api",
          error
        )();
        throw new ApiError(error.message, null);
      }
    }
  }
};

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';


export function toggleCollapse(
  showComponent: boolean,
  setShowComponent: React.Dispatch<React.SetStateAction<boolean>>
): void {
  if ( showComponent ) {
    setShowComponent(false)
  } else {
    setShowComponent(true)
  }
}

interface CollapseIconProps {
  showComponent: boolean,
  setShowComponent: React.Dispatch<React.SetStateAction<boolean>>
}

export function CollapseIcon(
  { showComponent, setShowComponent }: CollapseIconProps
): React.ReactElement {
    return (
    <ExpandMoreIcon
      color="primary"
      className="clickable"
      onClick={() => toggleCollapse(showComponent, setShowComponent)}
    />
  );
}

import InfoIcon from '@mui/icons-material/Info';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';


export class Progress {
  value = 0;
  start = 0;
  end = 100;

  /**
   * @param value The current progress of the task, as a percentage (0 - 100).
   * @param start The starting percentage of the current task. If multiple tasks are being done,
   *  this can be used to divide up the tasks.
   * @param end The ending percentage of the current task. If multiple tasks are being done,
   *  this can be used to divide up the tasks.
   */
  constructor(value = 0, start = 0, end = 100) {

    this.value = value;
    this.start = start;
    this.end = end;
  }
}


interface ProgressButtonProps {
  buttonTitle: string;
  isLoading: boolean;
  progress: Progress;
  offsetButton?: number;
  offsetBar?: number;
  colSizeButton?: number;
  colSizeBar?: number;
  type?: "submit" | "button" | "reset" | undefined;
  showSpinner?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function ProgressButton({
  buttonTitle, isLoading, progress, offsetButton=4, offsetBar=2, colSizeButton=4,
  colSizeBar=8, type="submit", onClick
}: ProgressButtonProps
): React.ReactElement {

  return (
    <>
      <Row>
        <Col className={`col-${colSizeButton} offset-${offsetButton}`}>
          {onClick !== undefined &&
            <Button variant="primary" type={type} onClick={onClick}>{buttonTitle}</Button>
          }
          {onClick === undefined &&
            <Button variant="primary" type={type}>{buttonTitle}</Button>
          }
        </Col>
      </Row>
      <Row>
        <Col className={`col-${colSizeBar} offset-${offsetBar}`}>
          {isLoading &&
            <ProgressBar animated now={progress.value} label={`${Math.round(progress.value)}%`} />
          }
          {!isLoading &&
            <div></div>
          }
        </Col>
      </Row>
    </>
  );
}


interface LoadingButtonProps {
  buttonTitle: string;
  isLoading: boolean;
  offset?: number;
  colSizeButton?: number;
  colSizeSpinner?: number;
  type?: "submit" | "button" | "reset" | undefined;
  showSpinner?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function LoadingButton(
  { buttonTitle, isLoading, offset=4, colSizeButton=4,
  colSizeSpinner = 1, type = "submit", showSpinner=true, onClick
}: LoadingButtonProps
): React.ReactElement {

  return (
    <>
      <Col className={`col-${colSizeButton} offset-${offset}`}>
        {onClick !== undefined &&
          <Button variant="primary" type={type} onClick={onClick}>{buttonTitle}</Button>
        }
        {onClick === undefined &&
          <Button variant="primary" type={type}>{buttonTitle}</Button>
        }
      </Col>
      <Col className={`col-${colSizeSpinner}`}>
        {(isLoading && showSpinner) &&
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Updating...</span>
          </Spinner>
        }
        {(!isLoading || !showSpinner) &&
          <div></div>
        }
      </Col>
    </>
  );
}

interface ProgressIndicatorProps {
  isLoading: boolean;
  progress: Progress;
}

export function ProgressIndicator({
  isLoading, progress
}: ProgressIndicatorProps
): React.ReactElement {

  return (
    <>
      {isLoading &&
        <ProgressBar animated now={progress.value} label={`${Math.round(progress.value)}%`} />
      }
      {!isLoading &&
        <div></div>
      }
    </>
  );
}

interface LoadingSpinnerProps {
  isLoading: boolean;
}

export function LoadingSpinner(
  { isLoading }:
  LoadingSpinnerProps
): React.ReactElement {

  return (
    <>
      {isLoading &&
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      }
      {!isLoading &&
        <div></div>
      }
    </>
  );
}


interface InfoTooltipProps {
  text: string;
}

export function InfoTooltip(
  { text }: InfoTooltipProps
): React.ReactElement {

  const [open, setOpen] = useState(false);

  function updateOpen(): void {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  function closeTooltip(): void {
    setOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={closeTooltip}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={closeTooltip}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={text}
          >
            <Button variant="outline-secondary" type="button" size="sm"
              className="infoTooltipButton" onClick={updateOpen}
            >
              <InfoIcon></InfoIcon>
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
}

import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useEffect,useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { ApiError } from "../../api/Api";
import type { UploadProgress } from "../../api/FlowFiles";
import { deleteFile, getUploadProgress, uploadFile } from "../../api/FlowFiles";
import { Alert, AlertInfo } from "../../components/Alerts";
import { 
  LoadingSpinner, Progress, ProgressButton, ProgressIndicator
} from '../../components/Buttons';
import {
FlowFileInfo,
setFileToNull, updateFileInfoInList,   updateFileInfoLocalList} from '../../components/Files';
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


interface UserFilesProps {
  fileInfoStorageList: Array<FlowFileInfo>;
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
}

function UserFiles(
  { fileInfoStorageList, setFileInfoStorageList }: UserFilesProps
): React.ReactElement {

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(new Progress(0));
  const [alertInfo, setAlertInfo] = useState(new AlertInfo("", false, "danger"));
  const [fileInfo, setFileInfo] = useState<FlowFileInfo>();
  const [fileInfoLocalList, setFileInfoLocalList] = useState<Array<FlowFileInfo>>([]);

  function handleGetData(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    uploadDataFromFiles(
      fileInfoLocalList, setIsLoading, setProgress, setAlertInfo,
      fileInfoStorageList, setFileInfoStorageList
    );
  }

  return (
    <Container fluid className="af-body">
      <Card>
        <Card.Header as="h5">Files</Card.Header>
        <Card.Body>
          <Row>
            <Form onSubmit={(event) => handleGetData(event)}>
              <Form.Group controlId="formFileMultiple" className="mb-3">
                <label htmlFor="upload-flow-file-local-selector" className="form-label">
                  Select a file from your computer:
                </label>
                <Row>
                  <Form.Control type="file" name="filename" id="upload-flow-file-local-selector"
                    onChange={(event) => updateFileInfoLocalList(
                      event as React.ChangeEvent<HTMLInputElement>,
                      setFileInfoLocalList, setFileInfo
                    )}
                    onClick={(event) => setFileToNull(
                      event as React.MouseEvent<HTMLInputElement, MouseEvent>
                    )}
                    multiple
                  />
                </Row>
                <ProgressButton buttonTitle="Get Data" isLoading={isLoading} progress={progress} />
                <Row>
                  <Alert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
                </Row>
              </Form.Group>
            </Form >
          </Row>
          <Row>
            <FileTable
              fileInfoStorageList={fileInfoStorageList}
              setFileInfoStorageList={setFileInfoStorageList}
            />
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}


/**
 * Load data from *.fcs file(s) on the backend.
 *
 * This function is called when the file(s) are changed in UploadFlowFile.
 * A new FlowData object will be created.
 *
 * @param fileInfoLocalList - A list of FlowFileInfo objects which correspond to *.fcs files
 * the user has submitted to upload from their computer.
 * @param setIsLoading - A function to set the loading state. If the data is being loaded, the
 * progress bar will be displayed.
 * @param setProgress - A function to set the progress state and update the progress bar.
 * @param setAlertInfo - A function to set the alert state. If there is an error, an alert will
 * be displayed.
 * @param fileInfoStorageList - A list of FlowFileInfo objects which correspond to *.fcs files in
 * the Firestore Database.
 * @param setFileInfoStorageList - A function to set the list of FlowFileInfo objects from
 * the Firestore Database.
*/
async function uploadDataFromFiles(
  fileInfoLocalList: Array<FlowFileInfo>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setProgress: React.Dispatch<React.SetStateAction<Progress>>,
  setAlertInfo: React.Dispatch<React.SetStateAction<AlertInfo>>,
  fileInfoStorageList: Array<FlowFileInfo>,
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>
): Promise<void> {

  if (fileInfoLocalList.length === 0) {
    logger.error(
      "uploadDataFromFiles",
      "api",
      "There are no files to upload."
    )();
  } else {
    logger.info(
      "uploadDataFromFiles",
      "api",
      `Loading ${fileInfoLocalList.length} files...`
    )();
    try {
      setProgress(new Progress(5, 5, 100));
      setIsLoading(true);
      const progressIncrement = 95 / fileInfoLocalList.length;
      const firestoreField = "name";
      const fileNameList = fileInfoStorageList.map((fileInfo) => { return fileInfo.name; });
      const newFileNames: Array<string> = [];
      let fileProgress = new Progress(5, 5, 5 + progressIncrement);
      await Promise.all(fileInfoLocalList.map(async (fileInfo: FlowFileInfo) => {
        const firestoreDocument = `flow_data/${fileInfo.name}`;
        await uploadFile(
          fileInfo.file!, fileProgress, setProgress, firestoreDocument, firestoreField
        );
        fileProgress = new Progress(
          fileProgress.end, fileProgress.end, fileProgress.end + progressIncrement
        );
        setProgress(fileProgress);
        if (!fileNameList.includes(fileInfo.name)) {
          newFileNames.push(fileInfo.name);
        }
      }));
      const newFileInfoStorageList = [...fileInfoStorageList];
      newFileNames.forEach((fileName) => {
        newFileInfoStorageList.push(new FlowFileInfo({
          name: fileName,
          location: "storage",
          type: ""
        }));
      });
      setFileInfoStorageList(newFileInfoStorageList);
    } catch (error) {
      if (error instanceof ApiError) {
        setAlertInfo(new AlertInfo(error.message, true, "danger"));
      }
    } finally {
      await new Promise(r => setTimeout(r, 800));
      setIsLoading(false);
    }
  }
}


interface FileTableProps {
  fileInfoStorageList: Array<FlowFileInfo>;
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>
}

function FileTable(
  { fileInfoStorageList, setFileInfoStorageList }: FileTableProps
): React.ReactElement {

  return (
    <Table hover className="fileTable">
      <thead>
        <tr>
          <th>File Name</th>
          <th>Processing</th>
          <th>Delete</th>
          <th></th>
          <th>Refresh</th>
        </tr>
      </thead>
      <tbody>
        {fileInfoStorageList.map((fileInfo) => (
          <FileTableRow fileInfo={fileInfo} fileInfoStorageList={fileInfoStorageList}
            setFileInfoStorageList={setFileInfoStorageList}
          />
        ))}
      </tbody>
    </Table>
  )
}


interface FileTableRowProps {
  fileInfo: FlowFileInfo;
  fileInfoStorageList: Array<FlowFileInfo>;
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>
}

function FileTableRow(
  { fileInfo, fileInfoStorageList, setFileInfoStorageList }: FileTableRowProps
): React.ReactElement {

  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [progress, setProgress] = useState(new Progress(100));


  useEffect(() => {
    logger.info(
      "useEffect: []",
      "files",
      fileInfo.name,
      "initial render"
    )();
    checkUploadProgress(
      fileInfo, fileInfoStorageList, setFileInfoStorageList, setProgress, setUploadIsLoading
    );
  }, []);

  useEffect(() => {
    logger.info(
      "useEffect: [fileInfo.name]",
      "files",
      fileInfo.name,
      "fileInfo.progress",
      fileInfo.progress
    )();
    if (fileInfo.progress !== undefined) {
      if (fileInfo.progress.cluster != null && fileInfo.progress.cluster < 1.0) {
        if (fileInfo.progress.cluster === -1.0) {
          setProgress(new Progress(fileInfo.progress.cluster));
        } else {
          setProgress(new Progress(fileInfo.progress.cluster * 100));
        }
      } else if (fileInfo.progress.firestore != null && fileInfo.progress.firestore < 1.0) {
        if (fileInfo.progress.firestore === -1.0) {
          setProgress(new Progress(fileInfo.progress.firestore));
        } else {
          setUploadIsLoading(true);
          setProgress(new Progress(fileInfo.progress.firestore * 100));
        }
      }
    } else {
      setUploadIsLoading(false);
      setProgress(new Progress(100));
    }
  }, [fileInfo.name]);

  async function deleteClickHandler(
    fileName: string,
    setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> {
    setIsLoading(true);
    await deleteFile(fileName, setFileInfoStorageList);
    setIsLoading(false);
  }

  return (
    <tr key={fileInfo.name}>
      <td className="fileTableColName">
        {fileInfo.name}
      </td>
      <td className="fileTableColProcessing">
        {progress.value === -1.0 &&
          <ErrorIcon color="error" />
        }
        {progress.value !== -1.0 &&
          <ProgressIndicator isLoading={uploadIsLoading} progress={progress} />
        }
      </td>
      <td className="fileTableColDelete">
        <DeleteIcon
          color="primary"
          className="clickable"
          onClick={() => deleteClickHandler(
            fileInfo.name, setFileInfoStorageList, setDeleteIsLoading
          )}
        />
      </td>
      <td className="fileTableColDelete">
        <LoadingSpinner isLoading={deleteIsLoading} />
      </td>
      <td>
        <RefreshIcon
          color="primary"
          className="clickable"
          onClick={() => checkUploadProgress(
            fileInfo, fileInfoStorageList, setFileInfoStorageList, setProgress, setUploadIsLoading
          )}
        />
      </td>
    </tr>
  )
}

async function checkUploadProgress(
  fileInfo: FlowFileInfo,
  fileInfoStorageList: Array<FlowFileInfo>,
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
  setProgress: React.Dispatch<React.SetStateAction<Progress>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {

  if (fileInfo.progress === undefined ||
    fileInfo.progress.cluster == null ||
    (fileInfo.progress.cluster < 1.0 && fileInfo.progress.cluster !== -1.0) ||
    fileInfo.progress.firestore == null ||
    (fileInfo.progress.firestore < 1.0 && fileInfo.progress.firestore !== -1.0)
  ) {
    const progress: UploadProgress = await getUploadProgress(
      fileInfo.name, new Progress(0, 0, 100), true
    );
    setProgress(progress);
    if (progress.value === 100) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
    const newFileInfo = new FlowFileInfo({
      ...fileInfo,
      progress: {
        firestore: progress.firestore,
        cluster: progress.cluster
      }
    });
    updateFileInfoInList(newFileInfo, fileInfoStorageList, setFileInfoStorageList);
  }
}

export default UserFiles;

import type * as d3 from 'd3';
import React, { forwardRef,memo } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import isEqual from "react-fast-compare";

import type { FlowData } from "../../../components/FlowData";
import { scaleBottom,scaleLeft } from '../../../components/plots/PlotComponents';
import type { QuadrantInfo } from '../../../components/plots/Quadrants';
import { FlowPlotLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import { getTimeElapsed } from "../../../utils/Utils";
import type { ClusterInfo } from "../Clusters";
import { ClusterFlowPlot } from "./ClusterFlowPlot";
import { DensityFlowPlot } from "./DensityFlowPlot";
import type { FlowPlotItem } from './FlowPlotItem';
import { areFlowPlotItemsEqual, areFlowPlotListsEqual } from './FlowPlotItem';
import {
  FRAGMENT_SHADER_SOURCE_CODE, getColorBufferData, getCoordinateData,
  VERTEX_SHADER_SOURCE_CODE, WebGLUtil
} from "./WebGL";



export const MARGIN = { top: 15, right: 40, bottom: 60, left: 60 };
export const CLUSTER_COLORS = [
  "#440154", "#8CDCEC", "#FFAAB8", "#0d6efd", "#FFEA5F", "#F98C09", "#5FA765", "#68ACB8",
  "#E2BEEB", "#01317A", "#BF354C", "#DD8493", "#126426", "#F3B21B", "#6A4580", "#9D0C46",
  "#8F54AB", "#194321", "#BA83CE", "#FAD13D", "#308D40", "#3B9FD8", "#D1576B", "#89CD8F",
  "#AE1E47", "#F59F14", "#CD9DDD", "#EFD5F6", "#C7F6FF", "#F16537", "#449C4F", "#F4741F",
  "#F292A2", "#FFB8C4", "#F59F14", "#258EE9", "#F6C02C", "#76C8D5", "#0E21B4", "#FCDD4F"
];
export const WIDTH = 280;
export const HEIGHT = 280;


const logger = new FlowPlotLogger(LOG_LEVEL, LOG_FILTERS);

export interface FlowPlotRef {
  flowPlotListRef: Array<FlowPlotItem> | null;
}


interface FlowPlotLayoutProps {
  plotType: string;
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  row: number;
  flowData: FlowData;
  clusterInfo: ClusterInfo;
  dataIsLoading: boolean;
  flowPlotItem?: FlowPlotItem;
  layoutType?: "hierarchical" | "flat";
}

export const FlowPlotLayout = forwardRef(function FlowPlotLayout(
  { plotType, flowPlotItem, flowPlotList, setFlowPlotList, row, flowData,
    clusterInfo, dataIsLoading, layoutType = "flat" }: FlowPlotLayoutProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const flowPlotListRef = ref as React.MutableRefObject<Array<FlowPlotItem>>;

  if (layoutType === "hierarchical") {
    return (
      <>
        <Container className="scrollHorizontal">
          {flowPlotListRef != null && flowPlotListRef.current !== undefined &&
            flowPlotListRef.current.map((flowPlotItem, index) => (
              <Row key={index}>
                <FlowPlotLayoutHierarchical flowPlotItem={flowPlotItem} plotType={plotType}
                  flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList} row={index}
                  flowData={flowData} clusterInfo={clusterInfo} dataIsLoading={dataIsLoading}
                  ref={ref}
                />
              </Row>
            ))}
          {flowPlotListRef == null || flowPlotListRef.current === undefined &&
            <Row>
            </Row>
          }
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Container className="scrollHorizontal">
          <Row>
            {flowPlotListRef.current.map((flowPlotItem) => (
              <FlowPlotLayoutFlat flowPlotItem={flowPlotItem} plotType={plotType}
                flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList} row={row}
                flowData={flowData} clusterInfo={clusterInfo} dataIsLoading={dataIsLoading}
                ref={ref}
              />
            ))}
          </Row>
        </Container>
      </>
    );
  }
});


interface FlowPlotLayoutHierarchicalProps extends FlowPlotLayoutProps {
  flowPlotItem: FlowPlotItem;
}

const FlowPlotLayoutHierarchical = memo(forwardRef(function(
  { plotType, flowPlotItem, flowPlotList, setFlowPlotList, row, flowData,
    clusterInfo, dataIsLoading }: FlowPlotLayoutHierarchicalProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  logger.info(
    "FlowPlotLayoutHierarchical",
    flowPlotItem.id,
    "",
    "Rendering FlowPlots: flowPlotList: ",
    flowPlotList
  )();

  const style = {
    "width": `${String(flowPlotItem.width + 50)  }px`
  }

  if (flowPlotItem.flowPlotSubList.length === 0) {
    logger.info(
      "FlowPlotLayoutHierarchical",
      flowPlotItem.id,
      "flowPlotSubList empty, calling FlowPlot"
    )();
    return (
      <Col className="flowPlot" key={row} style={style}>
        <FlowPlot flowPlotItem={flowPlotItem} plotType={plotType} flowPlotList={flowPlotList}
          setFlowPlotList={setFlowPlotList} flowData={flowData} clusterInfo={clusterInfo}
          dataIsLoading={dataIsLoading} ref={ref}
        />
      </Col>
    );
  } else {
    logger.info(
      "FlowPlotLayoutHierarchical",
      flowPlotItem.id,
      "flowPlotItem: ",
      flowPlotItem
    )();
    return (
      <>
        <Col className="flowPlot col-auto" key={row} style={style}>
          <FlowPlot flowPlotItem={flowPlotItem} plotType={plotType} flowPlotList={flowPlotList}
            setFlowPlotList={setFlowPlotList} flowData={flowData} clusterInfo={clusterInfo}
            dataIsLoading={dataIsLoading} ref={ref}
          />
        </Col>
        <Col className="flowPlot col-auto" style={style}>
          {flowPlotItem.flowPlotSubList.map((item, index) => (
            <Row key={index} className="flowPlot" style={style}>
              <FlowPlotLayoutHierarchical flowPlotItem={item} plotType={plotType}
                flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList} row={index}
                flowData={flowData} clusterInfo={clusterInfo}
                dataIsLoading={dataIsLoading} ref={ref}
              />
            </Row>
          ))}
        </Col>
      </>
    );
  }
}), areFlowPlotLayoutPropsEqual);


interface FlowPlotLayoutFlatProps extends FlowPlotLayoutProps {
  flowPlotItem: FlowPlotItem;
}

const FlowPlotLayoutFlat = memo(forwardRef(function(
  { plotType, flowPlotItem, flowPlotList, setFlowPlotList, row, flowData, clusterInfo,
    dataIsLoading }: FlowPlotLayoutFlatProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  logger.info(
    "FlowPlotLayoutFlat",
    flowPlotItem.id,
    "Rendering FlowPlots: flowPlotList: ",
    flowPlotList
  )();

  const style = {
    "width": `${String(flowPlotItem.width + 50)}px`
  }

  if (flowPlotItem.flowPlotSubList.length === 0) {
    logger.info(
      "FlowPlotLayoutFlat",
      flowPlotItem.id,
      "flowPlotSubList empty, calling FlowPlot"
    )();
    return (
      <Col className="flowPlot" key={row} style={style}>
        <FlowPlot flowPlotItem={flowPlotItem} plotType={plotType} flowPlotList={flowPlotList}
          setFlowPlotList={setFlowPlotList} flowData={flowData} clusterInfo={clusterInfo}
          dataIsLoading={dataIsLoading} ref={ref} />
      </Col>
    );
  } else {
    logger.info(
      "FlowPlotLayoutFlat",
      flowPlotItem.id,
      "flowPlotItem: ",
      flowPlotItem
    )();
    return (
      <>
        <Col className="flowPlot col-auto" key={row} style={style}>
          <FlowPlot flowPlotItem={flowPlotItem} plotType={plotType} flowPlotList={flowPlotList}
            setFlowPlotList={setFlowPlotList} flowData={flowData} clusterInfo={clusterInfo}
            dataIsLoading={dataIsLoading} ref={ref} />
        </Col>
        {flowPlotItem.flowPlotSubList.map((item, index) => (
          <FlowPlotLayoutFlat flowPlotItem={item} plotType={plotType}
            flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList} row={index}
            flowData={flowData} clusterInfo={clusterInfo} dataIsLoading={dataIsLoading} ref={ref}
          />
        ))}
      </>
    );
  }
}), areFlowPlotLayoutPropsEqual);


function areFlowPlotLayoutPropsEqual(
  oldProps: FlowPlotLayoutFlatProps,
  newProps: FlowPlotLayoutFlatProps
): boolean {
  logger.info(
    "areFlowPlotLayoutPropsEqual",
    "props",
    oldProps.flowPlotItem.id,
    "oldProps:\n",
    oldProps,
    "\nnewProps\n",
    newProps
  )();
  if (newProps.dataIsLoading) {
    return true;
  }
  else if (
    isEqual(oldProps.plotType, newProps.plotType) &&
    isEqual(oldProps.flowData, newProps.flowData) &&
    isEqual(oldProps.clusterInfo.idsShown, newProps.clusterInfo.idsShown) &&
    isEqual(oldProps.clusterInfo.ids, newProps.clusterInfo.ids) &&
    areFlowPlotListsEqual(
      oldProps.flowPlotList, newProps.flowPlotList, ["quadrantInfo", "xScale", "yScale"]
    ) &&
    areFlowPlotItemsEqual(
      oldProps.flowPlotItem, newProps.flowPlotItem, ["quadrantInfo", "xScale", "yScale"]
    )
  ) {
    logger.info(
      "areFlowPlotLayoutPropsEqual?",
      "props",
      oldProps.flowPlotItem.id,
      `props are equal? true`
    )();
    return true;
  } else {
    logger.info(
      "areFlowPlotLayoutPropsEqual?",
      "props",
      oldProps.flowPlotItem.id,
      `props are equal? false`
    )();
    return false;
  }
}


interface FlowPlotProps {
  flowPlotItem: FlowPlotItem;
  plotType: string;
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  flowData: FlowData;
  clusterInfo: ClusterInfo;
  dataIsLoading: boolean;
}

export const FlowPlot = memo(forwardRef(function(
  { flowPlotItem, plotType, flowPlotList, setFlowPlotList, flowData, clusterInfo,
    dataIsLoading }: FlowPlotProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  if (plotType === "density") {
    logger.info(
      "FlowPlot",
      flowPlotItem.id,
      "Rendering FlowPlot: DensityFlowPlot"
    )();
    return (
      <>
        <DensityFlowPlot flowPlotItem={flowPlotItem}
          flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList}
          flowData={flowData} dataIsLoading={dataIsLoading} ref={ref}
        />
      </>
    );
  } else if (plotType === "clusters") {
    logger.info(
      "FlowPlot",
      flowPlotItem.id,
      "Rendering FlowPlot: ClusterFlowPlot"
    )();
    return (
      <>
        <ClusterFlowPlot flowPlotItem={flowPlotItem}
          flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList}
          scatterplotType={"cluster"} flowData={flowData} clusterInfo={clusterInfo}
          dataIsLoading={dataIsLoading} ref={ref} />
      </>
    );
  }
}), areFlowPlotPropsEqual);

function areFlowPlotPropsEqual(
  oldProps: FlowPlotProps, newProps: FlowPlotProps
): boolean {
  logger.info(
    "areFlowPlotPropsEqual",
    "props",
    oldProps.flowPlotItem.id,
    "oldProps:\n",
    oldProps,
    "\nnewProps\n",
    newProps
  )();
  if (newProps.dataIsLoading) {
    return true;
  } else if (
    isEqual(oldProps.plotType, newProps.plotType) &&
    isEqual(oldProps.flowData, newProps.flowData) &&
    isEqual(oldProps.clusterInfo.idsShown, newProps.clusterInfo.idsShown) &&
    isEqual(oldProps.clusterInfo.ids, newProps.clusterInfo.ids) &&
    areFlowPlotItemsEqual(
      oldProps.flowPlotItem, newProps.flowPlotItem, ["quadrantInfo", "xScale", "yScale"], true
    )
  ) {
    logger.info(
      "areFlowPlotPropsEqual?",
      "props",
      oldProps.flowPlotItem.id,
      `props are equal? true`
    )();
    return true;
  } else {
    logger.info(
      "areFlowPlotPropsEqual?",
      "props",
      oldProps.flowPlotItem.id,
      `props are equal? false`
    )();
    return false;
  }
}


/**
 * Draw the scatterplot points using WebGL.
 *
 * @param flowPlotItem - the FlowPlotItem we are creating a color scale for.
 * @param xScale - a scale which converts a value from the x array to a value for the x-axis.
 * @param yScale - a scale which converts a value from the y array to a value for the y-axis.
 * @param canvasRefCurrent - the React Ref object for the plot canvas element.
 * @param scatterplotType - one of "cluster", "density", or "umap".
 * @param colorScaleList - an array containing a dictionary with a different color scale
 *  for each cluster ID.
 * @returns
 */
export function drawPoints(
  flowPlotItem: FlowPlotItem,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>,
  canvasRefCurrent: HTMLCanvasElement,
  scatterplotType: string,
  colorScaleList: Array<ColorScaleInfo>
): void {

  const start = Date.now();
  let layers: Record<string, Array<number>> = {};
  if (Object.keys(flowPlotItem.layers).length === 0 || scatterplotType === "density") {
    layers = {
      "0": [...Array(flowPlotItem.x.length).keys()]
    }
  } else {
    layers = flowPlotItem.layers
  }
  logger.info(
    "drawPoints",
    "draw",
    flowPlotItem.id,
    "drawing points"
  )();

  const webGL = new WebGLUtil(canvasRefCurrent, [0, 0, 0, 0]);

  const vertexShader = webGL.getShader(VERTEX_SHADER_SOURCE_CODE, webGL.gl.VERTEX_SHADER);
  const fragmentShader = webGL.getShader(FRAGMENT_SHADER_SOURCE_CODE, webGL.gl.FRAGMENT_SHADER);
  webGL.setProgram(vertexShader, fragmentShader);

  const newColorScaleList = [];
  const layerKeys = Object.keys(layers);
  for (const colorScaleInfo of colorScaleList) {
    if (layerKeys.includes(String(colorScaleInfo.clusterId))) {
      newColorScaleList.push(colorScaleInfo);
    }
  }

  for (const colorScaleInfo of newColorScaleList) {

    const clusterId = colorScaleInfo.clusterId;
    const layerKey = String(clusterId);
    const layer = layers[layerKey];
    const colorScale = colorScaleInfo.colorScale;

    logger.info(
      "drawPoints",
      "draw",
      flowPlotItem.id,
      `clusterId: ${clusterId}`,
      "\nlayers: ",
      layers
    )();

    const xData: Array<number> = [];
    const yData: Array<number> = [];
    const colorData: Array<number> = [];

    if (scatterplotType === "umap") {
      for (const index of layer) {
        xData.push(flowPlotItem.x[index]);
        yData.push(flowPlotItem.y[index]);
        colorData.push(clusterId);
      }
    } else {
      for (const index of layer) {
        xData.push(flowPlotItem.x[index]);
        yData.push(flowPlotItem.y[index]);
        colorData.push(flowPlotItem.density[index]);
      }
    }

    const coordinateData = getCoordinateData(xData, yData, xScale, yScale);
    const positionBuffer = webGL.createAndBindBuffer(
      webGL.gl.ARRAY_BUFFER,
      webGL.gl.STATIC_DRAW,
      coordinateData
    );
    webGL.setVertexAttribPointer(
      "position", webGL.gl.ARRAY_BUFFER, positionBuffer, 2, webGL.gl.FLOAT
    );

    const colorBufferData = getColorBufferData(colorData, colorScale);
    const colorBuffer = webGL.createAndBindBuffer(
      webGL.gl.ARRAY_BUFFER,
      webGL.gl.STATIC_DRAW,
      colorBufferData
    );
    webGL.setVertexAttribPointer("color", webGL.gl.ARRAY_BUFFER, colorBuffer, 4, webGL.gl.FLOAT);

    webGL.drawArrays(webGL.gl.POINTS, 0, layer.length);
  }
  const timeElapsed = getTimeElapsed(start);
  logger.info(
    "drawPoints",
    "time",
    flowPlotItem.id,
    `time elapsed to draw points: ${timeElapsed} seconds`,
    `scatterplot type: ${scatterplotType}`
  )();
}




export function createXScale(x: Array<number>, boundsWidth: number) {
  let newX = [0, 1];
  if (x.length > 1) {
    newX = x;
  }
  const xScale = scaleBottom(newX, boundsWidth);
  return xScale;
}

export function createYScale(y: Array<number>, boundsHeight: number) {
  let newY = [0, 1];
  if (y.length > 1) {
    newY = y;
  }
  const yScale = scaleLeft(newY, boundsHeight);
  return yScale;
}



export interface ColorScaleInfo {
  clusterId: number;
  colorScale: d3.ScaleLinear<string, string> | d3.ScaleOrdinal<number, string, string>;
}



export function checkIfShouldAddQuadrant(flowPlotItem: FlowPlotItem, quadrantInfo: QuadrantInfo) {
  logger.info(
    "checkIfShouldAddQuadrant",
    "quadrants",
    flowPlotItem.id,
    `quadrantId updated: ${quadrantInfo.quadrantId}`
  )();
  let shouldAddQuadrant = true;

  if (flowPlotItem.flowPlotSubList.length > 0) {
    for (const item of flowPlotItem.flowPlotSubList) {
      if (item.quadrantInfo!.parentQuadrantId === quadrantInfo.quadrantId) {
        shouldAddQuadrant = false;
      }
    }
  }
  logger.info(
    "checkIfShouldAddQuadrant",
    "quadrants",
    flowPlotItem.id,
    `should add quadrant? ${shouldAddQuadrant}`
  )();
  return shouldAddQuadrant;
}

import { signOut } from "firebase/auth";
import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "../../firebase/firebase.utils";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


export default function Logout(): React.ReactElement {
  const navigate = useNavigate();
  const [notice, setNotice] = useState("");

  async function logout(): Promise<void> {

    try {
      await signOut(auth);
      navigate("/home");
    } catch (error) {
      logger.error(
        "logout",
        "Error logging out: ",
        error
      )();
      setNotice("There was an error logging out.");
    }
  }

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        {"" !== notice &&
          <div className="alert alert-warning" role="alert">
            {notice}
          </div>
        }
      </div>
    </div>
  )
}

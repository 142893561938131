import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

import { loadAnnotations, saveAnnotations } from "../../api/manual-gate/Annotations";
import { Alert, AlertInfo } from '../../components/Alerts';
import { LoadingButton } from '../../components/Buttons';
import { CollapseIcon } from '../../components/Collapsibles';
import { setFileToNull,updateFile } from '../../components/Files';
import { FlowPlotLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import type {ClusterInfo} from "./Clusters";
import { CELL_TYPES, updateClusterIdsShown, updateShowAllClusterIds } from "./Clusters";
import { CLUSTER_COLORS } from "./plots/FlowPlots";


const logger = new FlowPlotLogger(LOG_LEVEL, LOG_FILTERS);


interface AnnotationsCardProps {
  clusterInfo: ClusterInfo;
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>;
  flowFileName: string;
}

export function AnnotationsCard(
  { clusterInfo, setClusterInfo, flowFileName }: AnnotationsCardProps
): React.ReactElement {

  const [showCard, setShowCard] = useState(true);

  return (
    <>
    {showCard &&
      <Card>
        <Card.Header as="h5">
          Annotations
          <CollapseIcon 
              showComponent={showCard} setShowComponent={setShowCard}
            />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Annotations clusterInfo={clusterInfo} setClusterInfo={setClusterInfo} 
                flowFileName={flowFileName} 
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    }
    {!showCard &&
      <Card>
      <Card.Header as="h5" className="collapsed">
        Annotations
        <CollapseIcon 
            showComponent={showCard} setShowComponent={setShowCard}
          />
      </Card.Header>
      </Card>
    }
    </>
  );
};


interface AnnotationsProps {
  clusterInfo: ClusterInfo;
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>;
  flowFileName: string;
}
  
  
export function Annotations(
  { clusterInfo, setClusterInfo, flowFileName }: AnnotationsProps
): React.ReactElement {
  
  const [isLoading, setIsLoading] = useState(false);
  const [annotationsFile, setAnnotationsFile] = useState<File>();
  const [annotationsFileName, setAnnotationsFileName] = useState("");
  const [alertInfo, setAlertInfo] = useState(new AlertInfo("", false, "danger"));

  useEffect(() => {
    if (flowFileName !== "") {
      setAnnotationsFileName(`${flowFileName.replace('.fcs', '')}_annotations.csv`);
    }
  }, [flowFileName]);

  const annotationOptions = CELL_TYPES.map((label, index) =>
    <option value={label} key={index}>{label}</option>
  );

  function updateAnnotationsFileName(
    event: React.ChangeEvent<HTMLInputElement>,
    setAnnotationsFileName: React.Dispatch<React.SetStateAction<string>>
  ): void {
  
    const target = event.target as HTMLInputElement;
    setAnnotationsFileName(target.value);
  };

  return (
    <>
      <Row>
        <Alert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
      </Row>
      <Row>
        <Col className="col-12">
          <Form onSubmit={(event) => loadAnnotations(
            event, annotationsFile, clusterInfo, setClusterInfo, setIsLoading, setAlertInfo
          )}>
            <Form.Group controlId="annotationsFile" className="mb-3">
              <Row>
                <Col className="col-7">
                  <Form.Control type="file" name="annotationsFile"
                    onChange={(event) => updateFile(
                      event as React.ChangeEvent<HTMLInputElement>, setAnnotationsFile
                    )}
                    onClick={(event) => setFileToNull(
                      event as React.MouseEvent<HTMLInputElement, MouseEvent>
                    )}
                  />
                </Col>
                <LoadingButton buttonTitle="Load Annotations" isLoading={isLoading} offset={0}
                  colSizeButton={5} showSpinner={false} type="submit" />
              </Row>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col className="col-2">
          <Switch
            color="default"
            onChange={(event) => updateShowAllClusterIds(
              event, clusterInfo, setClusterInfo
            )}
            style={{ color: "#FFFFFF" }}
            defaultChecked={true}
          />
        </Col>
        <Col className="col-3 my-auto">
          Select All
        </Col>
      </Row>
      {clusterInfo.labels.map((clusterLabel, index) => (
        <Row key={index}>
          <Col className="col-2">
            <Switch
              value={index}
              color="default"
              onChange={(event) => updateClusterIdsShown(
                event, clusterInfo, setClusterInfo
              )}
              style={{ color: CLUSTER_COLORS[index] }}
              checked={clusterInfo.idsShown.includes(index)}
            />
          </Col>
          <Col className="col-8">
            <Form.Select aria-label="Channel selection"
              onChange={(event) => updateAnnotation(
                event, index, clusterInfo, setClusterInfo
              )}
              value={clusterLabel}>
              {annotationOptions}
            </Form.Select>
          </Col>
        </Row>
      ))}
      <Row>
        <Form>
          <OverlayTrigger
            key={"annotationsOverlay"}
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-annotations`}>
                {annotationsFileName}
              </Tooltip>
            }
          >
          <Form.Group className="mb-3" controlId="annotationsFileName">
            <Form.Label>File Name (*.csv)</Form.Label>

            <Form.Control size="sm" type="text" defaultValue={annotationsFileName}
              onChange={(event) => updateAnnotationsFileName(
                event as React.ChangeEvent<HTMLInputElement>, setAnnotationsFileName
              )}
              isValid={annotationsFileName !== "" && annotationsFileName.includes(".csv")}
              isInvalid={annotationsFileName !== "" && !annotationsFileName.includes(".csv")}
            />
            <Form.Control.Feedback type="invalid">
              Must be a valid *.csv file name.
            </Form.Control.Feedback>
          </Form.Group>
          </OverlayTrigger>
        </Form>
        <LoadingButton buttonTitle="Save Annotations" isLoading={isLoading} offset={2}
          colSizeButton={8} colSizeSpinner={2} type="button"
          onClick={(event) => saveAnnotations(
            event, clusterInfo, setIsLoading, annotationsFileName, flowFileName
          )}
        />
      </Row>
    </>
  );
  };
  
  
function updateAnnotation(
  event: React.ChangeEvent<HTMLSelectElement>,
  clusterId: number,
  clusterInfo: ClusterInfo,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>
): void {

  const clusterLabel = event.target.value;
  const clusterLabels = [...clusterInfo.labels];
  clusterLabels.splice(clusterId, 1, clusterLabel);

  logger.info(
      "updateAnnotation",
      "NA",
      "annotation",
      "clusterId: ",
      clusterId
  )();
  setClusterInfo({
      ...clusterInfo,
      labels: clusterLabels
  });
}
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from '@mui/material/MenuItem';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import React, { useState } from 'react';
import { Link } from "react-router-dom";

import { FlowFileInfo } from '../../../components/Files';
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


interface FlowFileStorageSelectorProps {
  fileInfo: FlowFileInfo | undefined;
  setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>;
  fileInfoStorageList: Array<FlowFileInfo>;
  setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
  id: string;
}

export function FlowFileStorageSelector({
  fileInfo, setFileInfo, fileInfoStorageList, setFileInfoDisplayList, id
}: FlowFileStorageSelectorProps
): React.ReactElement {

  const [selected, setSelected] = useState<Array<string>>([]);
  const isAllSelected = fileInfoStorageList.length > 0 &&
    selected.length === fileInfoStorageList.length;

  function updateFileInfo(
    event: SelectChangeEvent<Array<string>>
  ): void {
    let newSelected: Array<string> = event.target.value as Array<string>;
    if (newSelected[newSelected.length - 1] === "all") {
      newSelected = selected.length === fileInfoStorageList.length
        ? []
        : fileInfoStorageList.map((fileInfo) => { return fileInfo.name });
    }
    setSelected(newSelected);
    const newFileInfoDisplayList: Array<FlowFileInfo> = newSelected.map((fileName) => {
      let newFileInfo = new FlowFileInfo({
        name: fileName,
        location: "storage",
        type: ""
      });
      for (const fileInfoVar of fileInfoStorageList) {
        if (fileInfoVar.name === fileName) {
          newFileInfo = new FlowFileInfo({
            ...fileInfoVar,
            name: fileName,
            location: "storage",
            type: ""
          });
        }
      }
      return newFileInfo;
    });
    logger.info(
      "updateFileInfo",
      "files",
      "newFileInfoList",
      newFileInfoDisplayList
    )();
    setFileInfoDisplayList(newFileInfoDisplayList);
    if (fileInfo === undefined || !newSelected.includes(fileInfo.name)) {
      setFileInfo(newFileInfoDisplayList[0]);
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
        <Select
          labelId={id}
          multiple
          value={selected}
          onChange={updateFileInfo}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {fileInfoStorageList.length === 0 &&
            <MenuItem
              value="all"
            >
              <p>
                Please <Link to="/login">login</Link> to access your files
              </p>
            </MenuItem>
          }
          {fileInfoStorageList.length > 0 &&
            <MenuItem
              value="all"
            >
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    selected.length > 0 && selected.length < fileInfoStorageList.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary="Select All"
              />
            </MenuItem>
          }
          {fileInfoStorageList.map((fileInfo) => (
            <MenuItem key={fileInfo.name} value={fileInfo.name}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(fileInfo.name) > -1} />
              </ListItemIcon>
              <ListItemText primary={fileInfo.name} />
            </MenuItem>
          ))
          }
        </Select>
      </FormControl>
    </div >
  );
}

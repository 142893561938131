

export function getTimeElapsed(start: number, end?: number) {
  if (end === undefined) {
    end = Date.now();
  }
  const timeElapsed = (end - start) / 1000;
  return timeElapsed
}

/**
 * Find the maximum value of an array of numbers.
 * @param {Array<number>} arr - an array of numbers.
 */
export function findMax(arr: Array<number>) {
  const initialValue = -Infinity;
  return arr.reduce(
    (accumulator, currentValue) => accumulator < currentValue ? currentValue : accumulator,
    initialValue
  );
}


/**
 * Find the minimum value of an array of numbers.
 * @param {Array<number>} arr - an array of numbers.
 */
export function findMin(arr: Array<number>) {
  const initialValue = Infinity;
  return arr.reduce(
    (accumulator, currentValue) => accumulator > currentValue ? currentValue : accumulator,
    initialValue
  );
}

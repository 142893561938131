import React from 'react';
import Container from 'react-bootstrap/Container';


function AutoGate() {

  return (
    <Container fluid className="af-body">
    </Container>
  );
}

export default AutoGate;

import type { FlowData } from "../../components/FlowData";
import type {   FlowPlotItem} from "../../pages/manual-gate/plots/FlowPlotItem";
import {
convertFlowPlotListToObject,   convertObjectsToFlowPlotList, updateFlowPlotList,
updateFlowPlotListDataTree} from "../../pages/manual-gate/plots/FlowPlotItem";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import { ApiError,apiPost } from "../Api";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


export async function loadGatingScheme(
  event: React.FormEvent<HTMLFormElement>,
  file: File | undefined,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  flowData: FlowData,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): Promise<void> {

  event.preventDefault();
  if (file === undefined) {
    logger.error(
      "loadGatingScheme",
      "File is undefined"
    )();
    return;
  }

  setIsLoading(true);
  const urlSuffix = "/manual_gate_load_gating_scheme";
  logger.info(
    "loadGatingScheme",
    "api",
    `Loading gating scheme: ${urlSuffix}`
  )();

  const formData = new FormData();

  formData.append("file", file);
  formData.append("fileName", file.name);
  formData.append("channels", JSON.stringify(flowData.channels));

  try {
    const responseData = await apiPost(urlSuffix, formData);
    logger.info(
      "loadGatingScheme",
      "api",
      "responseData",
      responseData
    )();

    const flowPlotObjectList = JSON.parse(responseData.flowPlotList);
    logger.info(
      "loadGatingScheme",
      "api",
      "flowPlotObjectList from JSON: ",
      flowPlotObjectList
    )();
    let flowPlotList = convertObjectsToFlowPlotList(flowPlotObjectList);
    logger.info(
      "loadGatingScheme",
      "api",
      "flowPlotList from converted flowPlotObjectList: ",
      flowPlotList
    )();

    flowPlotList = flowPlotList.map((flowPlotItem) => {
      flowPlotItem.numCells = flowData.numCells;
      return flowPlotItem;
    });

    flowPlotList = await updateFlowPlotListDataTree(flowPlotList, flowData, true);
    logger.info(
      "loadGatingScheme",
      "api",
      "flowPlotList with data updated from the backend: ",
      flowPlotList
    )();
    updateFlowPlotList(flowPlotList, setFlowPlotList, flowPlotListRef);


  } catch (error) {
    logger.error(
      "loadGatingScheme",
      "error loading gating scheme: ",
      error
    )();
    if (error instanceof ApiError) {
      throw error;
    } else {
      throw new ApiError(
        "Error loading gating scheme"
      );
    }
  } finally {
    setIsLoading(false);
  }
}

export async function saveGatingScheme(
  event: React.MouseEvent<HTMLButtonElement>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  flowFileName: string,
  flowPlotList: Array<FlowPlotItem>
): Promise<void> {

  event.preventDefault();
  setIsLoading(true);
  const urlSuffix = "/manual_gate_save_gating_scheme";
  logger.info(
    "saveGatingScheme",
    "api",
    "Saving gating scheme: ",
    urlSuffix
  )();

  const fileName = flowFileName.replace(".fcs", "");
  const formData = new FormData();
  const flowPlotListJSON = JSON.stringify(
    convertFlowPlotListToObject(
      flowPlotList,
      ["id", "populationName", "channelX", "channelY", "quadrantInfo"]
    )
  );
  formData.append("fileName", fileName);
  formData.append("flowPlotList", flowPlotListJSON);

  try {
    const responseData = await apiPost(urlSuffix, formData, "blob");
    logger.info(
      "saveGatingScheme",
      "api",
      "responseData",
      responseData
    )();
    const blob = new Blob([responseData])
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `${fileName}_gating_scheme.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    logger.error(
      "saveGatingScheme",
      "error saving gating scheme: ",
      error
    )();
  } finally {
    setIsLoading(false);
  }
}

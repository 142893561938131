import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import React, { forwardRef,memo, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { ApiError } from "../../../api/Api";
import { loadGatingScheme, saveGatingScheme } from "../../../api/manual-gate/SaveLoadGates";
import { Alert, AlertInfo } from "../../../components/Alerts";
import { LoadingButton } from '../../../components/Buttons';
import { CollapseIcon } from "../../../components/Collapsibles";
import { setFileToNull,updateFile } from '../../../components/Files';
import type { FlowData } from "../../../components/FlowData";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import {
  areFlowPlotListsEqual, deleteItemInFlowPlotListTree, FlowPlotItem, flowPlotItemInList,
  updateFlowPlotItemData, updateFlowPlotList
} from "../plots/FlowPlotItem";
import { ChannelDropdown } from "./Channels";

const isEqual = require("react-fast-compare");

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


/**
 * Add a row to the ChannelTable.
 *
 * This function is called when a user clicks on the "+" icon in the ChannelTable.
 *
 * @param {number} row - the row of the table.
 * @param {Array<FlowPlotItem>} flowPlotList - the main list of all flow plots.
 * @param {React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>} setFlowPlotList - the
 *  React setter function for the State object flowPlotList.
 * @param {FlowData} flowData - the object containing the *.fcs data.
 */
export async function addRow(
  row: number,
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  flowData: FlowData,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): Promise<void> {

  logger.info(
    "addRow",
    "ChannelTable",
    "Adding row to flowPlotList: ",
    flowPlotList
  )();
  let newFlowPlotItem = new FlowPlotItem({
    numCells: flowData.numCells,
    channelX: flowData.channels[0],
    channelY: flowData.channels[1]
  });
  newFlowPlotItem = await updateFlowPlotItemData(
    newFlowPlotItem, flowPlotList, flowData
  );
  const newFlowPlotList = [...flowPlotListRef.current];
  newFlowPlotList.splice(row, 0, newFlowPlotItem);
  updateFlowPlotList(newFlowPlotList, setFlowPlotList, flowPlotListRef);
  logger.info(
    "addRow",
    "ChannelTable",
    "Row added to flowPlotList: ",
    newFlowPlotList
  )();
};


/**
 * Remove a row from the ChannelTable.
 *
 * This function is called when a user clicks on the "-" icon in the ChannelTable.
 * We want to make sure that we don't delete the only remaining row in the table. So, if the
 * flowPlotList has only one item in level 0 of the tree, and the flowPlotItem is in level 0,
 * we won't remove the row.
 *
 * @param {FlowPlotItem} flowPlotItem - the flow plot that coincides with the selected dropdown.
 * @param {Array<FlowPlotItem>} flowPlotList - the main list of all flow plots.
 * @param {React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>} setFlowPlotList - the
 *  React setter function for the State object flowPlotList.
 */
function removeRow(
  flowPlotItem: FlowPlotItem,
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): void {

  let shouldDelete = false;
  if (flowPlotList.length > 1) {
    shouldDelete = true;
  } else {
    logger.info(
      "removeRow",
      "ChannelTable",
      "Checking if this is the root list"
    )();
    const [isInList,] = flowPlotItemInList(flowPlotItem, flowPlotList);
    if (!isInList) {
      shouldDelete = true;
    } else {
      logger.info(
        "removeRow",
        "ChannelTable",
        "Not removing only row"
      )();
    }
  }

  if (shouldDelete) {
    logger.info(
      "removeRow",
      "ChannelTable",
      "Removing row from flowPlotList: ",
      flowPlotList
    )();
    const newFlowPlotList = deleteItemInFlowPlotListTree(flowPlotItem, flowPlotList);
    if (flowPlotListRef.current !== undefined) {
      flowPlotListRef.current = newFlowPlotList;
    }
    updateFlowPlotList(newFlowPlotList, setFlowPlotList, flowPlotListRef);
  }
};


function toggleCollapse(
  showSubTable: boolean,
  setShowSubTable: React.Dispatch<React.SetStateAction<boolean>>
): void {
  if (showSubTable) {
    setShowSubTable(false)
  } else {
    setShowSubTable(true)
  }
}



interface ChannelTableProps {
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  flowPlotSubList: Array<FlowPlotItem>;
  flowData: FlowData;
  initial: boolean;
}

const ChannelTable = memo(
  forwardRef(
    function ChannelTable(
  { flowPlotList, setFlowPlotList, flowPlotSubList, flowData, initial }: ChannelTableProps,
      ref: React.ForwardedRef<Array<FlowPlotItem>>
) {

  if (initial) {
    return (
      <Table hover className="channelTable">
        <thead>
          <tr>
            <th>Population</th>
            <th>Channel X</th>
            <th>Channel Y</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {flowPlotSubList.map((flowPlotItem, row) => (
            <ChannelTableTree row={row} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
              setFlowPlotList={setFlowPlotList} flowData={flowData} ref={ref}
            />
          ))}
        </tbody>
      </Table>
    );
  }
  else {
    return (
      <Table hover className="channelTable">
        <tbody>
          {flowPlotSubList.map((flowPlotItem, row) => (
            <ChannelTableTree row={row} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
              setFlowPlotList={setFlowPlotList} flowData={flowData} ref={ref}
            />
          ))}
        </tbody>
      </Table>
    );
  }
}), areChannelTablePropsEqual);


function areChannelTablePropsEqual(
  oldProps: ChannelTableProps, newProps: ChannelTableProps
): boolean {
  logger.info(
    "areChannelTablePropsEqual",
    "props",
    "oldProps:\n",
    oldProps,
    "\nnewProps\n",
    newProps
  )();
  if (
    isEqual(oldProps.flowData, newProps.flowData) &&
    areFlowPlotListsEqual(
      oldProps.flowPlotList, newProps.flowPlotList, ["quadrantInfo", "xScale", "yScale"]
    )
  ) {
    return true;
  } else {
    return false;
  }
}


interface ChannelTableTreeProps {
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  flowData: FlowData;
  flowPlotItem: FlowPlotItem;
  row: number;
}

const ChannelTableTree = forwardRef(function ChannelTableTree(
  { flowPlotList, setFlowPlotList, flowData, flowPlotItem, row }: ChannelTableTreeProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const [showSubTable, setShowSubTable] = useState(false);

  if (flowPlotItem.flowPlotSubList.length === 0) {
    logger.info(
      "ChannelTableTree",
      "ChannelTable",
      "flowPlotItem: ",
      flowPlotItem
    )();
    return (
      <ChannelTableRow row={row} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
        setFlowPlotList={setFlowPlotList}
        flowData={flowData}
        showSubTable={showSubTable}
        setShowSubTable={setShowSubTable}
        ref={ref}
      />
    );
  } else {
    return (
      <>
        <ChannelTableRow row={row} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
          setFlowPlotList={setFlowPlotList}
          flowData={flowData} showSubTable={showSubTable}
          setShowSubTable={setShowSubTable} ref={ref}
        />
        {showSubTable &&
          <tr>
            <td colSpan={5} className="channelTableCol">
              <ChannelTable flowPlotList={flowPlotList}
                setFlowPlotList={setFlowPlotList} flowPlotSubList={flowPlotItem.flowPlotSubList}
                flowData={flowData} initial={false} ref={ref}
              />
            </td>
          </tr>
        }
        {!showSubTable &&
          <></>
        }

      </>
    );
  }
});


interface ChannelTableRowProps {
  row: number;
  flowPlotItem: FlowPlotItem;
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  flowData: FlowData;
  showSubTable: boolean;
  setShowSubTable: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChannelTableRow = forwardRef(function ChannelTableRow(
  { row, flowPlotItem, flowPlotList, setFlowPlotList, flowData, showSubTable,
    setShowSubTable }: ChannelTableRowProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  return (
    <tr key={flowPlotItem.id}>
      <td className="channelTableColName">
        {flowPlotItem.populationName}
      </td>
      <td key={0} className="channelTableColX">
        <ChannelDropdown col={0} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
          setFlowPlotList={setFlowPlotList} flowData={flowData} dropdownType="channelTable"
          ref={ref}
        />
      </td>
      <td key={1} className="channelTableColY">
        <ChannelDropdown col={1} flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
          setFlowPlotList={setFlowPlotList} flowData={flowData} dropdownType="channelTable"
          ref={ref}
        />
      </td>
      <td className="channelTableColPlusMinus">
        <AddCircleIcon
          color="primary"
          className="clickable"
          onClick={
            () => addRow(row + 1, flowPlotList, setFlowPlotList, flowData,
              ref as React.MutableRefObject<Array<FlowPlotItem>>)
          }
        />
        <RemoveCircleIcon
          color="primary"
          className="clickable"
          onClick={() => removeRow(flowPlotItem, flowPlotList, setFlowPlotList,
            ref as React.MutableRefObject<Array<FlowPlotItem>>)}
        />
      </td>
      <td className="channelTableColCollapse">
        <ExpandMoreIcon
          color="primary"
          className="clickable"
          onClick={() => toggleCollapse(showSubTable, setShowSubTable)}
        />
      </td>
    </tr>
  );
});


interface ChannelTableCardProps {
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  flowData: FlowData;
}

export const ChannelTableCard = forwardRef(function ChannelTableCard(
  { flowPlotList, setFlowPlotList, flowData }: ChannelTableCardProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const [isLoading, setIsLoading] = useState(false);
  const [gatingFile, setGatingFile] = useState<File>();
  const [alertInfo, setAlertInfo] = useState(new AlertInfo("", false, "danger"));
  const [showChannelTableCard, setShowChannelTableCard] = useState(true);

  async function loadGatingSchemeHandler(
    event: React.FormEvent<HTMLFormElement>,
    gatingFile: File | undefined,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    flowData: FlowData,
    setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
    ref: React.ForwardedRef<Array<FlowPlotItem>>
  ): Promise<void> {
    try {
      await loadGatingScheme(
        event, gatingFile, setIsLoading, flowData, setFlowPlotList,
        ref as React.MutableRefObject<Array<FlowPlotItem>>
      );
    } catch (error) {
      logger.error(
        "loadGatingSchemeHandler",
        "channels",
        "Error loading gating scheme: ",
        error
      )();
      if (error instanceof ApiError) {
        setAlertInfo(new AlertInfo(error.message, true, "danger"));
      }
    }
  }

  return (
    <>
      {showChannelTableCard &&
        <Card>
          <Card.Header as="h5">
            Population Gating
            <CollapseIcon 
              showComponent={showChannelTableCard} setShowComponent={setShowChannelTableCard}
            />
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="col-7">
                <Form onSubmit={(event) => loadGatingSchemeHandler(
                  event, gatingFile, setIsLoading, flowData, setFlowPlotList,
                  ref as React.MutableRefObject<Array<FlowPlotItem>>
                )}>
                  <Form.Group controlId="formFileSingle" className="mb-3">
                    <Row>
                      <Col className="col-6">
                        <Form.Control type="file" name="filename"
                          onChange={(event) => updateFile(
                            event as React.ChangeEvent<HTMLInputElement>, setGatingFile
                          )}
                          onClick={(event) => setFileToNull(
                            event as React.MouseEvent<HTMLInputElement, MouseEvent>
                          )}
                        />
                      </Col>
                      <LoadingButton buttonTitle="Load Gating Scheme" isLoading={isLoading}
                        offset={0} colSizeButton={5} showSpinner={false} type="submit" />
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
              <Col className="col-5">
                <Row>
                  <LoadingButton buttonTitle="Save Gating Scheme" isLoading={isLoading} offset={0}
                    colSizeButton={7} colSizeSpinner={2} type="button"
                    onClick={(event) => saveGatingScheme(
                      event, setIsLoading, flowData.fileName,
                      (ref as React.MutableRefObject<Array<FlowPlotItem>>)!.current
                    )}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <Alert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
            </Row>
            <Row>
              <ChannelTable flowPlotList={flowPlotList}
                setFlowPlotList={setFlowPlotList}
                flowPlotSubList={(ref as React.MutableRefObject<Array<FlowPlotItem>>)!.current}
                flowData={flowData} initial={true} ref={ref}
              />
            </Row>
          </Card.Body>
        </Card>
  }
  {!showChannelTableCard &&
      <Card>
        <Card.Header as="h5" className="collapsed">
          Population Gating
          <CollapseIcon
            showComponent={showChannelTableCard} setShowComponent={setShowChannelTableCard} 
          />
        </Card.Header>
      </Card>
  }
    </>
  );
});

import React, { useRef,useState } from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import type { FlowFileInfo } from "../../components/Files";
import type { FlowData } from "../../components/FlowData";
import { AnnotationsCard } from "./Annotations";
import { ChannelTableCard } from "./channels/ChannelTable";
import { ClusterInfo } from "./Clusters";
import { LoadFlowFile } from "./fcs-files/LoadFlowFile";
import { FlowPlotItem } from "./plots/FlowPlotItem";
import { FlowPlotLayout } from "./plots/FlowPlots";
import { UMAPCard } from "./plots/UMAPFlowPlot";
import type { CompParams} from "./PlotSettings";
import { LOGICLE_PARAMS,PlotSettings, TransParams } from "./PlotSettings";


const DEFAULT_SETTINGS = {
  numCells: 10000,
  plotType: "clusters",
  transType: "logicle",
  transParams: LOGICLE_PARAMS
}

export const UMAP_MARGIN = { top: 30, right: 40, bottom: 60, left: 40 };
export const UMAP_WIDTH = 350;
export const UMAP_HEIGHT = 350;

interface ManualGateProps {
  compParams: CompParams;
  setCompParams: React.Dispatch<React.SetStateAction<CompParams>>;
  flowFileInfoStorageList: Array<FlowFileInfo>;
  setFlowFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
  flowData: FlowData;
  setFlowData: React.Dispatch<React.SetStateAction<FlowData>>;
}

function ManualGate({
  compParams, setCompParams, flowFileInfoStorageList, setFlowFileInfoStorageList,
  flowData, setFlowData
}: ManualGateProps): React.ReactElement {

  const [flowFileInfo, setFlowFileInfo] = useState<FlowFileInfo>();
  const initialFlowPlotItem = new FlowPlotItem({});
  const [flowPlotList, setFlowPlotList] = useState([new FlowPlotItem({ ...initialFlowPlotItem })]);
  const flowPlotListRef = useRef<Array<FlowPlotItem>>(
    [new FlowPlotItem({ ...initialFlowPlotItem })]
  );
  const [plotType, setPlotType] = useState(DEFAULT_SETTINGS.plotType);
  const [transParams, setTransParams] = useState(
    new TransParams({ 
      transType: DEFAULT_SETTINGS.transType,
      parameters: DEFAULT_SETTINGS.transParams
    })
  );
  const [numCells, setNumCells] = useState(DEFAULT_SETTINGS.numCells);
  const [umapFlowPlotItem, setUmapFlowPlotItem] = useState(
    new FlowPlotItem({
      width: UMAP_WIDTH,
      height: UMAP_HEIGHT,
      margin: UMAP_MARGIN
    }));
  const [clusterInfo, setClusterInfo] = useState(new ClusterInfo({}));
  const [layoutType, setLayoutType] = useState<"flat" | "hierarchical">("flat");
  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [flowFileInfoDisplayList, setFlowFileInfoDisplayList] = useState<Array<FlowFileInfo>>([]);

  return (
    <Container fluid className="af-body">
      <Row>
        <Col className="col-3">
          <Tabs
            defaultActiveKey="load-flow-file"
            id="manual-gate-left-tabs"
            className="mb-3"
            fill
          >
            <Tab eventKey="load-flow-file" title="FCS Files">
              <LoadFlowFile fileInfo={flowFileInfo} setFileInfo={setFlowFileInfo}
                setFlowData={setFlowData} flowPlotList={flowPlotList}
                setFlowPlotList={setFlowPlotList} setClusterInfo={setClusterInfo}
                setUmapFlowPlotItem={setUmapFlowPlotItem} numCells={numCells}
                fileInfoStorageList={flowFileInfoStorageList}
                setFileInfoStorageList={setFlowFileInfoStorageList} 
                fileInfoDisplayList={flowFileInfoDisplayList}
                setFileInfoDisplayList={setFlowFileInfoDisplayList}
                compParams={compParams}
                transParams={transParams} setDataIsLoading={setDataIsLoading} ref={flowPlotListRef}
              />
            </Tab>
            <Tab eventKey="plot-settings" title="Plot Settings">
              <PlotSettings plotType={plotType} setPlotType={setPlotType}
                layoutType={layoutType} setLayoutType={setLayoutType}
                compParams={compParams} setCompParams={setCompParams}
                transParams={transParams} setTransParams={setTransParams}
                numCells={numCells} setNumCells={setNumCells}
                fileInfo={flowFileInfo} setFileInfo={setFlowFileInfo}
                fileInfoStorageList={flowFileInfoStorageList}
                setFileInfoStorageList={setFlowFileInfoStorageList}
                fileInfoDisplayList={flowFileInfoDisplayList}
                setFileInfoDisplayList={setFlowFileInfoDisplayList}
                flowData={flowData} setFlowData={setFlowData}
                flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList}
                setUmapFlowPlotItem={setUmapFlowPlotItem}
                setClusterInfo={setClusterInfo} setDataIsLoading={setDataIsLoading}
                ref={flowPlotListRef}
              />
            </Tab>
            <Tab eventKey="channel-table" title="Gating">
              <ChannelTableCard
                flowPlotList={flowPlotList} setFlowPlotList={setFlowPlotList} flowData={flowData}
                ref={flowPlotListRef}
              />
            </Tab>
            <Tab eventKey="annotations" title="Annotations">
              <AnnotationsCard clusterInfo={clusterInfo} setClusterInfo={setClusterInfo}
              flowFileName={flowData.fileName}
              />
            </Tab>
          </Tabs>

        </Col>
        <Col className="col-6">
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Header as="h5">
                  Flow Plots
                </Card.Header>
                <Card.Body>
                  <FlowPlotLayout plotType={plotType} flowPlotList={flowPlotList} row={0}
                    setFlowPlotList={setFlowPlotList} flowData={flowData} clusterInfo={clusterInfo}
                    ref={flowPlotListRef} layoutType={layoutType} dataIsLoading={dataIsLoading}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="col-3">
        <Row>
            <Col className="col-12">
              <UMAPCard flowPlotItem={umapFlowPlotItem} flowPlotList={flowPlotList}
                setFlowPlotList={setFlowPlotList} flowData={flowData} clusterInfo={clusterInfo}
                setClusterInfo={setClusterInfo} dataIsLoading={dataIsLoading} ref={flowPlotListRef}
              />
            </Col>
          </Row>
        </Col>
      </Row>

    </Container>
  );
}

export default ManualGate;

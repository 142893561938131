import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap';


function AppNavbar() {
  return (
    <Navbar expand="lg" bg="primary" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand href="/">Autoflow</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/manual-gate">
              <Nav.Link>Manual Gate</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/auto-gate">
              <Nav.Link>Auto Gate</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/compensation">
              <Nav.Link>Compensation</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/user-files">
              <Nav.Link>Files</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ms-auto">
            <NavDropdown title="Login" id="basic-nav-dropdown" align="end">
              <NavDropdown.Item href="/login">Login</NavDropdown.Item>
              <NavDropdown.Item href="/signup">Sign Up</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  );
}

export default AppNavbar;

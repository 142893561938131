import * as d3 from 'd3';

import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


export const VERTEX_SHADER_SOURCE_CODE = `#version 300 es
  precision mediump float;
  layout(location = 0) in vec4 position;
  layout(location = 1) in vec4 color;

  out vec4 theColor;

  void main () {
      gl_Position = vec4(position.x, position.y, 0.0, 1.0); // x,y,z,w
      gl_PointSize = 1.5;
      theColor = color;
  }
`;


export const FRAGMENT_SHADER_SOURCE_CODE = `#version 300 es
  precision mediump float;
  in vec4 theColor;
  out vec4 fragColor;

  void main () {
      fragColor = theColor; // r,g,b,a
  }
`;


export class WebGLUtil {

  gl: WebGL2RenderingContext;
  program?: WebGLProgram;
  canvas: HTMLCanvasElement;

  constructor(canvas: HTMLCanvasElement, bgColor: [number, number, number, number] = [1, 1, 1, 1]) {
    this.canvas = canvas;
    this.gl = canvas.getContext("webgl2")!;
    this.gl.clearColor(...(bgColor));
    this.gl.clear(this.gl.DEPTH_BUFFER_BIT | this.gl.COLOR_BUFFER_BIT);
  }

  getGLContext(canvas: HTMLCanvasElement, bgColor: [number, number, number, number] = [1, 1, 1, 1]):
    WebGL2RenderingContext {

    const gl = canvas.getContext("webgl2")!;

    gl.clearColor(...(bgColor));
    gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
    this.gl = gl;

    return this.gl;
  }

  getShader(sourceCode: string, shaderType: number): WebGLShader {
    const shader = this.gl.createShader(shaderType)!;

    this.gl.shaderSource(shader, sourceCode);
    this.gl.compileShader(shader);

    if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
      logger.error(
        "WebGLUtil.getShader", "Error compiling shader", this.gl.getShaderInfoLog(shader)
      )();
    }

    return shader;
  }

  setProgram(vertexShader: WebGLShader, fragmentShader: WebGLShader): void {
    const program = this.gl.createProgram()!;

    this.gl.attachShader(program, vertexShader);
    this.gl.attachShader(program, fragmentShader);
    this.gl.linkProgram(program);
    this.gl.useProgram(program);

    if (!this.gl.getProgramParameter(program, this.gl.LINK_STATUS)) {
      logger.error(
        "WebGLUtil.setProgram", "Error linking program", this.gl.getProgramInfoLog(program)
      )();
    }
    this.program = program;
  }

  createAndBindBuffer(bufferType: number, typeOfDrawing: number, data: BufferSource): WebGLBuffer {
    const buffer = this.gl.createBuffer();
    this.gl.bindBuffer(bufferType, buffer);
    this.gl.bufferData(bufferType, data, typeOfDrawing);
    if (buffer == null) {
      logger.error(
        "WebGLUtil.createAndBindBuffer", "Error creating buffer, buffer is null"
      )();
      throw new Error("Error creating buffer, buffer is null");
    } else {
      return buffer;
    }
  }

  setVertexAttribPointer(bufferVariable: string, bufferType: number, buffer: WebGLBuffer,
    dims: number, dataType: number, normalize = false, stride = 0,
    offset = 0
  ): void {
    if (this.program === undefined) {
      logger.error(
        "WebGLUtil.setVertexAttribPointer", "Need to set a program first, see setProgram"
      )();
      throw new Error("Need to set a program first, see setProgram");
    } else {
      const position = this.gl.getAttribLocation(this.program, bufferVariable);
      this.gl.bindBuffer(bufferType, buffer);
      this.gl.enableVertexAttribArray(position);
      this.gl.vertexAttribPointer(position, dims, dataType, normalize, stride, offset);
    }
  }

  drawArrays(mode: number, first: number, count: number): void {
    this.gl.drawArrays(mode, first, count);
  }

}


/**
 * Get the coordinates for each point converted in the form to give to the WebGL buffer.
 *
 * Convert the x and y coordinates to the graph coordinates, which are in the range [-1, 1].
 * To pass to the buffer, combine all the coordinate vectors into one long array.
 *
 * @param xData - an array of the x-coordinates.
 * @param yData - an array of the y-coordinates.
 * @param xScale - a d3 scale for the x-coordinates, which maps the x-coordinates (can be any range)
 * to the pixel coordinates. The pixel coordinates are in the range [0, boundsWidth].
 * @param yScale - a d3 scale for the y-coordinates, which maps the y-coordinates (can be any range)
 * to the pixel coordinates. The pixel coordinates are in the range [boundsHeight, 0].
 * @returns {Float32Array} - an array with the coordinates formatted to pass to the WebGL buffer.
 * The coordinates are in the form [x1, y1, x2, y2, ...], where x and y are in the range [-1, 1].
 */
export function getCoordinateData(
  xData: Array<number>,
  yData: Array<number>,
  xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>
): Float32Array {

  const coordinateData = new Float32Array(xData.length * 2);
  const xMin = xScale.range()[0]; // the left bound of the graph in pixels (0)
  const xMax = xScale.range()[1]; // the right bound of the graph in pixels (boundsWidth)
  const yMin = yScale.range()[0]; // the bottom bound of the graph in pixels (boundsHeight)
  const yMax = yScale.range()[1]; // the top bound of the graph in pixels (0)

  xData.map((value, index) => {
    const offset = index * 2;
    const coordinates = getCoordinates(
      xScale(xData[index]), yScale(yData[index]), xMin, xMax, yMin, yMax
    );
    coordinateData.set(coordinates, offset);
  });
  return coordinateData;
}

/**
   * Convert the pixel coordinates to the WebGL coordinates.
   * 
   * The pixel coordinates are in the range [0, boundsWidth] and [boundsHeight, 0]. The WebGL
   * coordinates are in the range [-1, 1]. We convert the pixel coordinates to the WebGL coordinates
   * by scaling the pixel coordinates to the range [0, 1] and then scaling to the range [-1, 1].
   * 
   * @see {@link https://opentechschool-brussels.github.io/intro-to-webGL-and-shaders/log3_draw-shape}
   *
   * @param x - x-coordinate in pixels.
   * @param y - y-coordinate in pixels.
   * @param xMin - the x-coordinate of the left bound of the graph in pixels.
   * @param xMax - the x-coordinate of the right bound of the graph in pixels.
   * @param yMin - the y-coordinate of the bottom bound of the graph in pixels.
   * Note that the y-coordinate starts at 0 at the top of the graph, so the bottom of the graph
   * is the highest y-value.
   * @param yMax - the y-coordinate of the top bound of the graph in pixels.
   * Note that the y-coordinate starts at 0 at the top of the graph, so the top of the graph
   * is the lowest y-value.
  * @returns {[number, number]} - an 2-vector with the transformed x and y coordinates, both in the
  * range [-1, 1].
*/
function getCoordinates(
  x: number, y: number, xMin = 0, xMax = 100, yMin = 0.0,
  yMax = 100
): [number, number] {
  const xTransformed = (x - xMin) / (xMax - xMin) * 2 - 1;
  const yTransformed = (y - yMin) / (yMax - yMin) * 2 - 1;
  const coordinates: [number, number] = [xTransformed, yTransformed];
  return coordinates;
}


/**
 * Get the color for each point converted in the form to give to the WebGL buffer.
 *
 * The `colorScale` variable returns an RGB value for each data point in the form of a string, e.g.:
 * "rgb(255, 255, 255)".
 * We convert this string to a vector with values between 0 and 1, e.g.:
 * [1.0, 1.0, 1.0, 1.0]
 * The first 3 values are the RGB values, and the third one is the opacity (alpha).
 * To pass to the buffer, we combine all the 4-vectors into one long array.
 *
 * @param {Array<number>} data - an array of values which will be used to determine the color for
 *  that data point.
 * @param {d3.ScaleLinear<string, string> | d3.ScaleOrdinal<number, string, string>} colorScale -
 *  a color scale which returns an RGB color for each value in the data array.
 * @returns {Float32Array} - an array with the color values formatted to pass to the WebGL buffer.
 */
export function getColorBufferData(data: Array<number>,
  colorScale: d3.ScaleLinear<string, string> | d3.ScaleOrdinal<number, string, string>
): Float32Array {

  const colorBufferData = new Float32Array(data.length * 4);
  data.map((value, index) => {
    const offset = index * 4;
    const color = rgb2vec(d3.color(colorScale(value))!.formatRgb());
    colorBufferData.set(color, offset);
  });
  return colorBufferData;
}



/**
 * Given a string specifying the RGB color values, return a 4-vector in the WebGL specifications.
 *
 * RGB color values are typically given in a range [0, 255]. However, WebGL requires that they be
 * in a range of [0, 1]. Also, we add the opacity factor a = 1.0 as the fourth element.
 *
 * @param {string} rgbColor - a string specifying RGB color values, e.g. "rgb(255, 255, 255)" or
 *  "rgb(255,255,255)".
 * @returns {[number, number, number, number]} - a 4-vector with RGB color values in WebGL
 * specifications.
 */
function rgb2vec(rgbColor: string): [number, number, number, number] {
  let lowerIndex = 0;
  let upperIndex = 0;
  if (rgbColor.match("^rgb[(]([0-9]{1,3}), ([0-9]{1,3}), ([0-9]{1,3})[)]$")) {
    if (rgbColor.match("^rgb[(]([0-9]{1}), ([0-9]{1,3}), ([0-9]{1,3})[)]$")) {
      upperIndex = 5;
    } else if (rgbColor.match("^rgb[(]([0-9]{2}), ([0-9]{1,3}), ([0-9]{1,3})[)]$")) {
      upperIndex = 6;
    } else {
      upperIndex = 7;
    }
    const r = parseInt(rgbColor.substring(4, upperIndex)) / 255;
    lowerIndex = upperIndex + 2;
    if (rgbColor.match("^rgb[(]([0-9]{1,3}), ([0-9]{1}), ([0-9]{1,3})[)]$")) {
      upperIndex = lowerIndex + 1;
    } else if (rgbColor.match("^rgb[(]([0-9]{1,3}), ([0-9]{2}), ([0-9]{1,3})[)]$")) {
      upperIndex = lowerIndex + 2;
    } else {
      upperIndex = lowerIndex + 3;
    }
    const g = parseInt(rgbColor.substring(lowerIndex, upperIndex)) / 255;
    lowerIndex = upperIndex + 2
    if (rgbColor.match("^rgb[(]([0-9]{1,3}), ([0-9]{1,3}), ([0-9]{1})[)]$")) {
      upperIndex = lowerIndex + 1;
    } else if (rgbColor.match("^rgb[(]([0-9]{1,3}), ([0-9]{1,3}), ([0-9]{2})[)]$")) {
      upperIndex = lowerIndex + 2;
    } else {
      upperIndex = lowerIndex + 3;
    }
    const b = parseInt(rgbColor.substring(lowerIndex, upperIndex)) / 255;
    return [r, g, b, 1.0];
  } 
    return [0, 0, 0, 1.0];
  
}


function hex2rgb(hexColor: string): [number, number, number, number] {
  if (hexColor.match("^#([A-Fa-f0-9]{6})$")) {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
    return [r, g, b, 1.0];
  } else if (hexColor.match("^([A-Fa-f0-9]{6})$")) {
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    return [r, g, b, 1.0];
  }
  
    return [0, 0, 0, 1.0];
  
}

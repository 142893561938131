import React from "react";

import { getDataInQuadrant } from "../../api/manual-gate/LoadData";
import { THEME_COLORS } from "../../App";
import {
  FlowPlotItem, getFlowPlotItemById, replaceItemInFlowPlotListTree
} from '../../pages/manual-gate/plots/FlowPlotItem';
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import type { Margin } from "./PlotComponents";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


const TICK_LENGTH = 10;
const MARGIN = { top: 60, right: 60, bottom: 60, left: 60 };


interface ConstructorParams {
  boundsHeight: number;
  boundsWidth: number;
  margin?: Margin;
  drawState?: "notDrawn" | "activeDrawing" | "staticDrawn";
  quadrantId?: number;
  parentQuadrantId?: number;
  xLinePercent?: number;
  yLinePercent?: number;
  verticalLine?: Record<string, number>;
  horizontalLine?: Record<string, number>;
}

interface QuadrantInfoObject extends ConstructorParams {
  verticalLine?: Record<string, number>;
  horizontalLine?: Record<string, number>;
}

class QuadrantInfo {
  boundsHeight!: number;
  boundsWidth!: number;
  drawState!: "notDrawn" | "activeDrawing" | "staticDrawn";
  private _verticalLine!: Record<string, number>;
  private _horizontalLine!: Record<string, number>;
  quadrantId!: number;
  parentQuadrantId!: number;
  margin!: Margin;
  xLinePercent: number;
  yLinePercent: number;

  constructor({ boundsHeight, boundsWidth, margin = MARGIN, drawState = "notDrawn", quadrantId = 0,
    parentQuadrantId = 0, xLinePercent = 0.5, yLinePercent = 0.5,
    verticalLine, horizontalLine
  }: ConstructorParams) {

    this.boundsHeight = boundsHeight;
    this.boundsWidth = boundsWidth;
    this.margin = margin;
    this.quadrantId = quadrantId;
    this.xLinePercent = xLinePercent;
    this.yLinePercent = yLinePercent;
    this.parentQuadrantId = parentQuadrantId;
    this.drawState = drawState;

    if (verticalLine === undefined) {
      this.verticalLine = {
        x1: this.margin.left + boundsWidth * xLinePercent,
        x2: this.margin.left + boundsWidth * xLinePercent,
        y1: this.margin.top - TICK_LENGTH,
        y2: this.margin.top + boundsHeight + TICK_LENGTH
      }
    } else {
      this.verticalLine = verticalLine;
    }

    if (horizontalLine === undefined) {
      this.horizontalLine = {
        x1: this.margin.left - TICK_LENGTH,
        x2: this.margin.left + boundsWidth + TICK_LENGTH,
        y1: this.margin.top + boundsHeight * yLinePercent,
        y2: this.margin.top + boundsHeight * yLinePercent
      }
    } else {
      this.horizontalLine = horizontalLine;
    }
  }

  toObject(): QuadrantInfoObject {
    const object: QuadrantInfoObject = {
      boundsHeight: this.boundsHeight,
      boundsWidth: this.boundsWidth,
      drawState: this.drawState,
      verticalLine: this.verticalLine,
      horizontalLine: this.horizontalLine,
      quadrantId: this.quadrantId,
      parentQuadrantId: this.parentQuadrantId,
      margin: this.margin,
      xLinePercent: this.xLinePercent,
      yLinePercent: this.yLinePercent
    };

    return object;
  }

  public get verticalLine(): Record<string, number> {
    return this._verticalLine;
  }

  public set verticalLine(verticalLine: Record<string, number>) {
    this._verticalLine = verticalLine;
  }

  public get horizontalLine(): Record<string, number> {
    return this._horizontalLine;
  }

  public set horizontalLine(horizontalLine: Record<string, number>) {
    this._horizontalLine = horizontalLine;
  }
}


interface QuadrantLayerProps {
  quadrantInfo: QuadrantInfo;
  setQuadrantInfo: React.Dispatch<React.SetStateAction<QuadrantInfo>>;
  boundsWidth: number;
  boundsHeight: number;
}

function QuadrantLayer(
  { quadrantInfo, setQuadrantInfo, boundsWidth, boundsHeight }: QuadrantLayerProps
): React.ReactElement {

  function getClickCoordinates(event: React.MouseEvent<SVGElement>):
    [number, number] {
    const target = event.target as HTMLElement;
    const dim = target.getBoundingClientRect();
    const x = event.clientX - dim.left + quadrantInfo.margin.left;
    const y = event.clientY - dim.top + quadrantInfo.margin.top;
    return [x, y];
  }

  function updateQuadrantOrigin(
    event: React.MouseEvent<SVGElement>, quadrantInfo: QuadrantInfo
  ): void {
    const [xCoord, yCoord] = getClickCoordinates(event);
    const newQuadrantInfo = new QuadrantInfo({
      ...quadrantInfo.toObject()
    })
    newQuadrantInfo.verticalLine = {
      x1: xCoord,
      x2: xCoord,
      y1: quadrantInfo.margin.top - TICK_LENGTH,
      y2: quadrantInfo.margin.top + boundsHeight + TICK_LENGTH
    };
    newQuadrantInfo.horizontalLine = {
      x1: quadrantInfo.margin.left - TICK_LENGTH,
      x2: quadrantInfo.margin.left + boundsWidth + TICK_LENGTH,
      y1: yCoord,
      y2: yCoord
    }
    setQuadrantInfo(newQuadrantInfo);
    logger.info(
      "QuadrantLayer.updateQuadrantOrigin",
      "origin updated"
    )();
    return;
  }

  function getCurrentQuadrant(
    event: React.MouseEvent<SVGElement>, quadrantInfo: QuadrantInfo
  ): void {
    const [xCoord, yCoord] = getClickCoordinates(event);
    let quadrantId = 0;
    if (xCoord > quadrantInfo.verticalLine.x1 && yCoord < quadrantInfo.horizontalLine.y1) {
      quadrantId = 1;
    } else if (xCoord > quadrantInfo.verticalLine.x1 && yCoord > quadrantInfo.horizontalLine.y1) {
      quadrantId = 4;
    } else if (xCoord < quadrantInfo.verticalLine.x1 && yCoord < quadrantInfo.horizontalLine.y1) {
      quadrantId = 2;
    } else if (xCoord < quadrantInfo.verticalLine.x1 && yCoord > quadrantInfo.horizontalLine.y1) {
      quadrantId = 3;
    } else {
      quadrantId = 0;
    }
    const newQuadrantInfo = new QuadrantInfo({
      ...quadrantInfo.toObject(),
      quadrantId: quadrantId
    })
    logger.info(
      "QuadrantLayer.getCurrentQuadrant",
      "quadrants",
      "newQuadrantInfo: ",
      newQuadrantInfo
    )();
    setQuadrantInfo(newQuadrantInfo);
    logger.info(
      "QuadrantLayer.getCurrentQuadrant",
      "quadrants",
      "quadrantId: ",
      quadrantId
    )();
  }

  return (
    <>
      {
        (() => {
          switch (true) {

            case (quadrantInfo.drawState === "notDrawn"): {
              return (
                <g width={boundsWidth} height={boundsHeight}></g>
              )
            }
            case (quadrantInfo.drawState === "activeDrawing"): {
              return (
                <g width={boundsWidth} height={boundsHeight}
                >
                  <rect
                    width={boundsWidth}
                    height={boundsHeight}
                    x={quadrantInfo.margin.left}
                    y={quadrantInfo.margin.top}
                    opacity={1}
                    stroke={"white"}
                    fill={"white"}
                    fillOpacity={0}
                    strokeWidth={0.5}
                    onClick={(event) => updateQuadrantOrigin(event, quadrantInfo)}
                    className="clickable"
                  />
                  <line
                    x1={quadrantInfo.verticalLine.x1}
                    x2={quadrantInfo.verticalLine.x2}
                    y1={quadrantInfo.verticalLine.y1}
                    y2={quadrantInfo.verticalLine.y2}
                    stroke={THEME_COLORS.primary}
                    strokeWidth={1}
                  />
                  <line
                    x1={quadrantInfo.horizontalLine.x1}
                    x2={quadrantInfo.horizontalLine.x2}
                    y1={quadrantInfo.horizontalLine.y1}
                    y2={quadrantInfo.horizontalLine.y2}
                    stroke={THEME_COLORS.primary}
                    strokeWidth={1}
                  />
                </g>
              )
            }
            case (quadrantInfo.drawState === "staticDrawn"): {
              return (
                <g width={boundsWidth} height={boundsHeight}>
                  <rect
                    width={boundsWidth}
                    height={boundsHeight}
                    x={quadrantInfo.margin.left}
                    y={quadrantInfo.margin.top}
                    opacity={1}
                    stroke={"white"}
                    fill={"white"}
                    fillOpacity={0}
                    strokeWidth={0.5}
                    onDoubleClick={(event) => getCurrentQuadrant(event, quadrantInfo)}
                    className="clickable"
                  />
                  <line
                    x1={quadrantInfo.verticalLine.x1}
                    x2={quadrantInfo.verticalLine.x2}
                    y1={quadrantInfo.verticalLine.y1}
                    y2={quadrantInfo.verticalLine.y2}
                    stroke={THEME_COLORS.darkGrey}
                    strokeWidth={1}
                  />
                  <line
                    x1={quadrantInfo.horizontalLine.x1}
                    x2={quadrantInfo.horizontalLine.x2}
                    y1={quadrantInfo.horizontalLine.y1}
                    y2={quadrantInfo.horizontalLine.y2}
                    stroke={THEME_COLORS.darkGrey}
                    strokeWidth={1}
                  />
                </g>
              )
            }
            default: {
              return (
                <g width={boundsWidth} height={boundsHeight}></g>
              )
            }
          }
        })()
      }
    </>
  );
}



function createPopulationNameQuadrant(
  parentPopulationName: string, quadrantId: number, channelX: string, channelY: string
): string {

  let populationName = "all";
  switch (quadrantId) {
    case 1:
      populationName = `${parentPopulationName}/${channelX}+${channelY}+`;
      break;
    case 2:
      populationName = `${parentPopulationName}/${channelX}-${channelY}+`;
      break;
    case 3:
      populationName = `${parentPopulationName}/${channelX}-${channelY}-`;
      break;
    case 4:
      populationName = `${parentPopulationName}/${channelX}+${channelY}-`;
      break;
  }
  return populationName;
}


/**
 * Update the quadrantInfo field in a flowPlotItem and the flowPlotList.
 *
 * @param flowPlotItem - the flow plot we are updating.
 * @param quadrantInfo - the updated QuadrantInfo object.
 * @param flowPlotList - the main list of all flow plots.
 * @param setFlowPlotList - the setState function for the main list of all flow plots.
 */
function updateQuadrantInfo(
  flowPlotItemId: string,
  quadrantInfo: QuadrantInfo,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): void {

  const flowPlotItem: FlowPlotItem = getFlowPlotItemById(
    flowPlotItemId, flowPlotListRef.current
  )[1];
  flowPlotItem.quadrantInfo = quadrantInfo;

  logger.info(
    "updateQuadrantInfo",
    "updating the quadrantInfo for given flowPlotItem recursively in flowPlotList",
    flowPlotItem.id
  )();
  logger.info(
    "updateQuadrantInfo",
    "flowPlotList: ",
    flowPlotListRef.current
  )();
  const newFlowPlotList = replaceItemInFlowPlotListTree(
    flowPlotItem, flowPlotListRef.current
  );
  flowPlotListRef.current = newFlowPlotList;
  logger.info(
    "updateQuadrantInfo",
    "flowPlotList: ",
    flowPlotListRef.current
  )();
  setFlowPlotList(newFlowPlotList);
}


/**
 * Add a new subplot for a given quadrant.
 *
 * This function is called when a user clicks on a new quadrant in a flow plot.
 * The purpose of this function is to create a new plot containing only cells from the
 * clicked quadrant as a subplot.
 * 1. Create a new FlowPlotItem called `newFlowPlotItem` for the new plot.
 * 2. Fetch the data from the server for the `newFlowPlotItem`.
 * 3. Add the `newFlowPlotItem` to list of subplots of the given `flowPlotItem`.
 * 4. Propogate the changes to the entire `flowPlotList` state ojbect via
 * `replaceItemInFlowPlotListTree`.
 *
 * @param flowPlotItem - the flow plot that has been clicked on.
 * @param quadrantId - the quadrant that was clicked on, one of [1, 2, 3, 4].
 * @param lowPlotList - the main list of all flow plots.
 */
async function addQuadrantSubPlot(
  flowPlotItemId: string,
  quadrantInfo: QuadrantInfo,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): Promise<void> {

  const flowPlotItem = getFlowPlotItemById(flowPlotItemId, flowPlotListRef.current)[1];

  const newQuadrantInfo = new QuadrantInfo({
    ...quadrantInfo.toObject(),
    quadrantId: 0,
    parentQuadrantId: quadrantInfo.quadrantId,
    drawState: "notDrawn"
  })

  const newFlowPlotItem = new FlowPlotItem({
    channelX: flowPlotItem.channelX,
    channelY: flowPlotItem.channelY,
    minDensity: flowPlotItem.minDensity,
    maxDensity: flowPlotItem.maxDensity,
    quadrantInfo: newQuadrantInfo
  });

  newFlowPlotItem.quadrantId = quadrantInfo.quadrantId;
  newFlowPlotItem.populationName = createPopulationNameQuadrant(
    flowPlotItem.populationName, quadrantInfo.quadrantId,
    flowPlotItem.channelX, flowPlotItem.channelY
  );

  const [x, y, density, clusters, layers, indices] = await getDataInQuadrant(
    flowPlotItem.x, flowPlotItem.y, flowPlotItem.density, flowPlotItem.clusters,
    quadrantInfo, flowPlotItem.xScale, flowPlotItem.yScale
  );

  newFlowPlotItem.x = x;
  newFlowPlotItem.y = y;
  newFlowPlotItem.density = density;
  newFlowPlotItem.clusters = clusters;
  newFlowPlotItem.layers = layers;
  newFlowPlotItem.numCells = x.length;
  newFlowPlotItem.indices = indices;

  flowPlotItem.flowPlotSubList = [
    ...flowPlotItem.flowPlotSubList,
    newFlowPlotItem
  ]

  logger.info(
    "addQuadrantSubPlot",
    "flowPlotItem: ",
    flowPlotItem
  )();
  logger.info(
    "addQuadrantSubPlot",
    "newFlowPlotItem: ",
    newFlowPlotItem.id
  )();
  logger.info(
    "addQuadrantSubPlot",
    "current flowPlotList: ",
    flowPlotListRef.current
  )();

  const newFlowPlotList = replaceItemInFlowPlotListTree(
    flowPlotItem, flowPlotListRef.current
  );

  flowPlotListRef.current = newFlowPlotList;
  logger.info(
    "addQuadrantSubPlot",
    "updated flowPlotList: ",
    flowPlotListRef.current
  )();
  setFlowPlotList(newFlowPlotList);
}


export { QuadrantInfo, QuadrantLayer, addQuadrantSubPlot, updateQuadrantInfo }

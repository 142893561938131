import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

import type { FlowData } from "../../components/FlowData";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import type { CompParams } from "../manual-gate/PlotSettings";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);



export class SpilloverMatrix {
  channels: Array<string>;
  data: Array<Array<number>>;

  constructor(channels: Array<string>, data: Array<Array<number>>) {

    this.channels = channels;
    this.data = data;
  }
}

interface CompensationProps {
  flowData: FlowData;
  compParams: CompParams;
  setCompParams: React.Dispatch<React.SetStateAction<CompParams>>;
}

function Compensation(
  { flowData, compParams, setCompParams }: CompensationProps
): React.ReactElement {


  return (
    <Container fluid className="af-body">
      <Card>
        <Card.Header as="h5" className="expanded">
          Compensation
        </Card.Header>
        <Card.Body>
        </Card.Body>
      </Card>
    </Container >
  );
}

export default Compensation;

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import React, { forwardRef, useEffect, useMemo } from 'react';
import { ArrowsMove } from 'react-bootstrap-icons';

import { getPlotData } from "../../api/manual-gate/LoadData";
import { 
  FlowPlotItem, replaceItemInFlowPlotListTree 
} from '../../pages/manual-gate/plots/FlowPlotItem';
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import { QuadrantInfo, QuadrantLayer, updateQuadrantInfo } from './Quadrants';

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


interface ChartOptionsProps {
  flowPlotItem: FlowPlotItem;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  quadrantInfo: QuadrantInfo;
  setQuadrantInfo: React.Dispatch<React.SetStateAction<QuadrantInfo>>;
}

export const ChartOptions = forwardRef(function ChartOptions(
  { flowPlotItem, setFlowPlotList, quadrantInfo, setQuadrantInfo }: ChartOptionsProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const chartOptionsHeight = 50;

  function updateShouldDraw(quadrantInfo: QuadrantInfo): void {
    let newDrawState: "notDrawn" | "activeDrawing" | "staticDrawn" = "notDrawn";
    switch (quadrantInfo.drawState) {
      case "notDrawn":
        newDrawState = "activeDrawing";
        break;
      case "activeDrawing":
        newDrawState = "staticDrawn";
        break;
      case "staticDrawn":
        newDrawState = "notDrawn";
        break;
      default:
        newDrawState = "notDrawn";
    }
    const newQuadrantInfo = new QuadrantInfo({
      ...quadrantInfo.toObject(),
      drawState: newDrawState
    })

    logger.info(
      "updateShouldDraw",
      "ChartOptions",
      "updated drawState to ",
      newDrawState
    )();

    updateQuadrantInfo(flowPlotItem.id, newQuadrantInfo, setFlowPlotList,
      ref as React.MutableRefObject<Array<FlowPlotItem>>
    );

    logger.info(
      "updateShouldDraw",
      "ChartOptions",
      "newQuadrantInfo",
      newQuadrantInfo.horizontalLine
    )();
    setQuadrantInfo(newQuadrantInfo);
    return;
  }

  return (
    <>
      <svg width={flowPlotItem.width} height={flowPlotItem.height}>
        <g width={flowPlotItem.boundsWidth} height={chartOptionsHeight}>
          <rect
            width={20}
            height={20}
            x={0}
            y={0}
            opacity={1}
            stroke={"white"}
            fill={"white"}
            fillOpacity={0}
            strokeWidth={0.5}
            onClick={() => updateShouldDraw(quadrantInfo)}
            className="clickable"
          />
          <ArrowsMove size={20} className="icon" />
        </g>
        <QuadrantLayer quadrantInfo={quadrantInfo} setQuadrantInfo={setQuadrantInfo}
          boundsWidth={flowPlotItem.boundsWidth} boundsHeight={flowPlotItem.boundsHeight}
        />
      </svg>
    </>
  );
});


interface RangeSliderProps {
  flowPlotItem: FlowPlotItem;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
}

export const RangeSlider = forwardRef(function RangeSlider(
  { flowPlotItem, setFlowPlotList }: RangeSliderProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>
): React.ReactElement {

  const [xMin, setXMin] = React.useState<number>(Math.min(...flowPlotItem.x));
  const [xMax, setXMax] = React.useState<number>(Math.max(...flowPlotItem.x));
  const [x, y] = React.useMemo(
    () => {return [flowPlotItem.x, flowPlotItem.y]},
    [flowPlotItem.channelX, flowPlotItem.channelY]
  );

  useEffect(() => {
    logger.info(
      "useEffect",
      "RangeSlider",
      "setting bounds to ",
      [Math.min(...flowPlotItem.x), Math.max(...flowPlotItem.x)]
    )(
    )
    setBounds([Math.min(...flowPlotItem.x), Math.max(...flowPlotItem.x)]);
  }, [flowPlotItem.channelX, flowPlotItem.channelY]);

  // useEffect(() => {
  //   const callUpdateBounds = async () => {
  //     await updateBounds([undefined, undefined]);
  //   }
  //   callUpdateBounds().catch((error) => {
  //     logger.error(
  //       "useEffect",
  //       "RangeSlider",
  //       "error updating bounds",
  //       error
  //       )();
  //   }
  //   );
  // }, []);

  function setBounds(bounds: Array<number>): void {
    setXMin(bounds[0]);
    setXMax(bounds[1]);
  }

  async function updateBounds(bounds: Array<number> | Array<undefined>): Promise<void> {


    const [newX, newY, density, maxDensity, minDensity] = await getPlotData(
      x, y, flowPlotItem.channelX, flowPlotItem.channelY,
      flowPlotItem.indices, bounds[0], bounds[1]
    );
    const newFlowPlotItem = new FlowPlotItem({
      ...flowPlotItem.toObject(),
      x: newX,
      y: newY,
      density: density,
      maxDensity: maxDensity,
      minDensity: minDensity
    });

    logger.info(
      "updateBounds",
      "RangeSlider",
      "updated bounds to ",
      bounds
    )();

    const newFlowPlotList = replaceItemInFlowPlotListTree(
      newFlowPlotItem, (ref as React.MutableRefObject<Array<FlowPlotItem>>).current
    );
    (ref as React.MutableRefObject<Array<FlowPlotItem>>).current = newFlowPlotList;
    setFlowPlotList(newFlowPlotList);
    setXMin(Math.min(...newX));
    setXMax(Math.max(...newX));
  }

  return (
    <Box sx={{ width: flowPlotItem.boundsWidth }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={[xMin, xMax]}
        onChangeCommitted={(event, newBounds) => updateBounds(newBounds as Array<number>)}
        onChange={(event, newBounds) => {setBounds(newBounds as Array<number>)}}
        valueLabelDisplay="auto"
        min={Math.min(...x)}
        max={Math.max(...x)}
        step={(Math.max(...x) - Math.min(...x)) / 10}
      />
    </Box>
  );
});
import * as d3 from 'd3';
import React, { forwardRef,memo, useEffect, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CollapseIcon } from '../../../components/Collapsibles';
import type { FlowData } from '../../../components/FlowData';
import { Axes } from '../../../components/plots/PlotComponents';
import { FlowPlotLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import type { ClusterInfo } from "../Clusters";
import {
  areFlowPlotItemsEqual, FlowPlotItem,
getFlowPlotItemById} from './FlowPlotItem';
import type {
  ColorScaleInfo} from "./FlowPlots";
import {
  CLUSTER_COLORS, createXScale, createYScale, drawPoints} from "./FlowPlots";

const isEqual = require("react-fast-compare");


const logger = new FlowPlotLogger(LOG_LEVEL, LOG_FILTERS);



interface UMAPCardProps {
  /** The object containing the data to be plotted. */
  flowPlotItem: FlowPlotItem;
  /** A list of all the data for the different plots. */
  flowPlotList: Array<FlowPlotItem>;
  /** The setState method for flowPlotList. */
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  /** The object containing the .fcs file data. */
  flowData: FlowData;
  clusterInfo: ClusterInfo;
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>;
  dataIsLoading: boolean;
}

export const UMAPCard = forwardRef(function UMAPCard(
  { flowPlotItem, flowPlotList, setFlowPlotList, flowData, clusterInfo, setClusterInfo,
    dataIsLoading }: UMAPCardProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) 
{

  const [showUMAPCard, setShowUMAPCard] = useState(true);

  return (
    <>
    {showUMAPCard &&
      <Card>
        <Card.Header as="h5">
          UMAP Plot
          <CollapseIcon 
              showComponent={showUMAPCard} setShowComponent={setShowUMAPCard}
            />
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <UMAPFlowPlot flowPlotItem={flowPlotItem} flowPlotList={flowPlotList}
                setFlowPlotList={setFlowPlotList} flowData={flowData} clusterInfo={clusterInfo}
                setClusterInfo={setClusterInfo} dataIsLoading={dataIsLoading} ref={ref} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    }
    {!showUMAPCard &&
      <Card>
      <Card.Header as="h5" className="collapsed">
        UMAP Plot
        <CollapseIcon 
            showComponent={showUMAPCard} setShowComponent={setShowUMAPCard}
          />
      </Card.Header>
      </Card>
    }
    </>
  );
});



/**
 * The data types for the UMAPFlowPlot component.
 *
 * @interface UMAPFlowPlotProps
 */
interface UMAPFlowPlotProps {
  /** The object containing the data to be plotted. */
  flowPlotItem: FlowPlotItem;
  /** A list of all the data for the different plots. */
  flowPlotList: Array<FlowPlotItem>;
  /** The setState method for flowPlotList. */
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  /** The object containing the .fcs file data. */
  flowData: FlowData;
  clusterInfo: ClusterInfo;
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>;
  dataIsLoading: boolean;
}

export const UMAPFlowPlot = memo(
  forwardRef(function(
  { flowPlotItem, flowPlotList, setFlowPlotList, flowData, clusterInfo, setClusterInfo,
    dataIsLoading
    }: UMAPFlowPlotProps,
    ref: React.ForwardedRef<Array<FlowPlotItem>>
  ){

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const scatterplotType = "umap";
  const xScale = createXScale(flowPlotItem.x, flowPlotItem.boundsWidth);
  const yScale = createYScale(flowPlotItem.y, flowPlotItem.boundsHeight);
  const [count, setCount] = useState(0);
  let newFlowPlotItem = new FlowPlotItem({});

  if (ref != null && typeof ref !== 'function') {
    newFlowPlotItem = getFlowPlotItemById(flowPlotItem.id, ref.current!)[1];
  }

  useEffect(() => {
    logger.info(
      "UMAPFlowPlot.useEffect => mount",
      flowPlotItem.id,
      "umap",
      "count: ",
      count
    )();

    if (canvasRef.current && flowPlotItem.x.length > 1 && count === 0 && !dataIsLoading) {
      logger.info(
        "UMAPFlowPlot.useEffect => mount",
        flowPlotItem.id,
        "umap",
        "clusterIds: ",
        clusterInfo.ids
      )();
      const colorScaleList = createColorScale(clusterInfo);
      drawPoints(flowPlotItem, xScale, yScale, canvasRef.current!, scatterplotType, colorScaleList);
      setCount(count + 1);
    }
  });

  useEffect(() => {
    if (canvasRef.current && flowPlotItem.x.length > 1 && count > 0 && !dataIsLoading) {

      logger.info(
        "UMAPFlowPlot.useEffect => flowPlotItem, idsShown",
        flowPlotItem.id,
        "umap",
        "clusterIds: ",
        clusterInfo.ids
      )();
      const colorScaleList = createColorScale(clusterInfo);
      drawPoints(flowPlotItem, xScale, yScale, canvasRef.current!, scatterplotType, colorScaleList);
    }
  }, [flowPlotItem.channelX, flowPlotItem.channelY, flowPlotItem.x, clusterInfo.idsShown]);

  return (
    <div className="umap-scatterplot">
      <Axes
        xLabel={flowPlotItem.channelX} yLabel={flowPlotItem.channelY}
        width={flowPlotItem.width} height={flowPlotItem.height}
        xScale={xScale} yScale={yScale} margin={flowPlotItem.margin}
      />
      <canvas width={flowPlotItem.boundsWidth} height={flowPlotItem.boundsHeight} ref={canvasRef}
        style={{ marginLeft: flowPlotItem.margin.left, marginTop: flowPlotItem.margin.top }}
      />
    </div>
  );
}), areUMAPFlowPlotPropsEqual);


function areUMAPFlowPlotPropsEqual(
  oldProps: UMAPFlowPlotProps, newProps: UMAPFlowPlotProps
): boolean {
  logger.info(
    "areUMAPFlowPlotPropsEqual",
    oldProps.flowPlotItem.id,
    "props",
    "oldProps:\n",
    oldProps,
    "\nnewProps\n",
    newProps
  )();
  if (newProps.dataIsLoading) {
    return true;
  } else if (
    isEqual(oldProps.flowData, newProps.flowData) &&
    isEqual(oldProps.clusterInfo.idsShown, newProps.clusterInfo.idsShown) &&
    areFlowPlotItemsEqual(
      oldProps.flowPlotItem, newProps.flowPlotItem, ["quadrantInfo", "xScale", "yScale"], true
    )
  ) {
    return true;
  } else {
    return false;
  }
}


/**
 * Get the basic color scale for each cluster ID.
 *
 * Each cluster ID is assigned a color.
 *
 * @param clusterInfo - object containing information about the cluster IDs, labels,
 *  and which ids are shown.
 * @returns an array containing a dictionary with a different color scale for each cluster ID.
 */
function createColorScale(clusterInfo: ClusterInfo):
  Array<ColorScaleInfo> {

  const clusterColors = [...CLUSTER_COLORS];
  for (const clusterId of clusterInfo.ids) {
    if (!clusterInfo.idsShown.includes(clusterId)) {
      clusterColors.splice(clusterId, 1, "grey");
    }
  }
  const colorScaleList = clusterInfo.ids.map((clusterId) => {
    const colorScale = d3.scaleOrdinal<number, string>()
      .domain([...Array(clusterInfo.ids.length).keys()])
      .range(clusterColors)
      .unknown("grey");
    return ({
      clusterId: clusterId,
      colorScale: colorScale
    });
  });

  return colorScaleList;
}



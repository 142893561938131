import type { FlowData } from "../../components/FlowData";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import { getTimeElapsed } from "../../utils/Utils";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);

export const CELL_TYPES = [
  "Lymphocytes",
  "Lymphocytes: T Cells",
  "Lymphocytes: T Cells: LGL",
  "Lymphocytes: T Cells: CD4+ Helper",
  "Lymphocytes: T Cells: CD4+ Helper: LGL",
  "Lymphocytes: T Cells: CD4+ Helper: TFH",
  "Lymphocytes: T Cells: CD8+ Cytotoxic",
  "Lymphocytes: T Cells: CD8+ Cytotoxic: LGL",
  "Lymphocytes: T Cells: CD4+CD8+",
  "Lymphocytes: T Cells: CD4-CD8-",
  "Lymphocytes: T Cells: NKT",
  "Lymphocytes: NK Cells",
  "Lymphocytes: B Cells",
  "Lymphocytes: B Cells: Naïve B",
  "Lymphocytes: B Cells: Memory B",
  "Lymphocytes: B Cells: Memory B: Pre-switch IgM+",
  "Lymphocytes: B Cells: Memory B: Pre-switch IgM-",
  "Lymphocytes: B Cells: Germinal Center B",
  "Lymphocytes: B Cells: Germinal Center B: Pre-switch IgM+",
  "Lymphocytes: B Cells: Germinal Center B: Post-switch IgM-",
  "Lymphocytes: B Cells: Hematogones",
  "Lymphocytes: Plasmablasts",
  "Lymphocytes: Plasma Cells",
  "Non-Lymphoid",
  "Non-Lymphoid: Basophils",
  "Non-Lymphoid: Dendritic Cells",
  "Non-Lymphoid: Monocytes",
  "Non-Lymphoid: Neutrophils",
  "Non-Lymphoid: Eosinophils",
  "Non-Lysed Red Blood Cells",
  "Debris"
];


interface ConstructorParams {
  ids?: Array<number>;
  labels?: Array<string>;
  idsShown?: Array<number>;
}

/**
 * A class containing the unique cluster IDs and their labels.
 * Default labels are just string version of the cluster IDs.
 * The order of the ids list will change depending on which layer is selected.
 * The order of the labels will NOT change and are indexed by the ids.
 */
export class ClusterInfo {
  ids: Array<number> = [];
  labels: Array<string> = [];
  idsShown: Array<number> = [];

  constructor({ ids = [], labels = [], idsShown = [] }: ConstructorParams) {

    this.ids = ids;
    this.labels = labels;
    this.idsShown = idsShown;
  }
}


export function updateClusterInfo(
  flowData: FlowData,
  clusterInfo: ClusterInfo,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>
): void {

  const start = Date.now();
  const clusterKeys: Array<string> = Object.keys(flowData.layers);
  const clusterIds: Array<number> = clusterKeys.map((key) => {
    return parseInt(key);
  });
  const clusterLabels: Array<string> = clusterKeys.map(() => {
    return CELL_TYPES[0]
  });
  logger.info(
    "updateClusters",
    "clusters",
    "clusterIds: ",
    clusterIds
  )();
  setClusterInfo({
    ...clusterInfo,
    ids: clusterIds,
    labels: clusterLabels,
    idsShown: clusterIds
  });

  const timeElapsed = getTimeElapsed(start);
  logger.info(
    "updateClusters",
    "clusters",
    `time elapsed: ${timeElapsed} seconds`
  )();
}


export function updateClusterIdsShown(
  event: React.ChangeEvent<HTMLInputElement>,
  clusterInfo: ClusterInfo,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>
): void {

  const clusterId = parseInt(event.target.value);
  const checked = event.target.checked;
  logger.info(
    "updateClusterIdsShown",
    "clusters",
    "checked: ",
    checked,
    "clusterId: \n",
    clusterId
  )();

  const clusterIdsShown = [...clusterInfo.idsShown];
  if (!checked) {
    logger.info(
      "updateClusterIdsShown",
      "clusters",
      "idsShown: ",
      clusterInfo.idsShown
    )();
    const index = clusterInfo.idsShown.indexOf(clusterId);
    clusterIdsShown.splice(index, 1);
  } else {
    clusterIdsShown.push(clusterId);
  }

  logger.info(
    "updateClusterIdsShown",
    "clusters",
    "clusterIdsShown: ",
    clusterIdsShown
  )();
  setClusterInfo({
    ...clusterInfo,
    idsShown: clusterIdsShown
  });
}

export function updateShowAllClusterIds(
  event: React.ChangeEvent<HTMLInputElement>,
  clusterInfo: ClusterInfo,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>
): void {

  const checked = event.target.checked;
  let clusterIdsShown = [...clusterInfo.ids];

  if (!checked) {
    clusterIdsShown = [];
  }

  logger.info(
    "updateShowAllClusterIds",
    "clusters",
    "clusterIdsShown: ",
    clusterIdsShown
  )();
  setClusterInfo({
    ...clusterInfo,
    idsShown: clusterIdsShown
  });
}

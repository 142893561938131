import React from 'react';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';

import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../utils/Logger";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


interface FileInfoParams {
  file?: File | undefined;
  name: string;
  location?: string;
  type: string;
  progress?: Record<string, number | null>;
}

export class FileInfo {
  file?: File;
  name: string;
  location: string;
  type: string;
  progress?: Record<string, number | null>;

  constructor({
    file = undefined, name, type, location = "local", progress = undefined
  }: FileInfoParams) {

    this.file = file;
    this.name = name;
    this.location = location;
    this.type = type;
    this.progress = progress;
  }
}


interface FlowFileInfoParams {
  file?: File | undefined;
  name: string;
  location?: string;
  type: string;
  progress?: Record<string, number | null>;
  totalEvents?: number;
  totalChunks?: number;
  numEventsPerChunk?: number;
  numEventsPerChunkHTTP?: number;
  isClustered?: boolean;
}

export class FlowFileInfo extends FileInfo {

  totalEvents?: number;
  totalChunks?: number;
  numEventsPerChunk?: number;
  numEventsPerChunkHTTP?: number;
  isClustered?: boolean;

  constructor({
    file = undefined, name, type, location = "local", progress = { firestore: null, cluster: null },
    totalEvents = undefined, totalChunks = undefined, numEventsPerChunk = undefined,
    numEventsPerChunkHTTP = undefined, isClustered = undefined
  }: FlowFileInfoParams) {

    super({ file, name, location, type, progress });
    this.totalEvents = totalEvents;
    this.totalChunks = totalChunks;
    this.numEventsPerChunk = numEventsPerChunk;
    this.numEventsPerChunkHTTP = numEventsPerChunkHTTP;
    this.isClustered = isClustered;
  }
}


/**
 * Given a FileInfo object, update the matching object in the fileInfoList.
 *
 * @param fileInfo - the FileInfo object to update.
 * @param fileInfoList - the list of FileInfo objects of all the files.
 * @param setFileInfoList - the React state setter for the list of FileInfo objects.
 */
export function updateFileInfoInList(
  fileInfo: FileInfo,
  fileInfoList: Array<FileInfo>,
  setFileInfoList: React.Dispatch<React.SetStateAction<Array<FileInfo>>>
): void {
  logger.info(
    "updateFileInfoInList",
    "files",
    fileInfo,
    fileInfoList
  )();
  const newFileInfoList = fileInfoList.map((fileInfoVar) => {
    if (fileInfoVar.name === fileInfo.name) {
      return {
        ...fileInfo
      };
    } else {
      return fileInfoVar;
    }
  });
  logger.info(
    "updateFileInfoInList",
    "files",
    newFileInfoList
  )();
  setFileInfoList(newFileInfoList);
}



/**
 * Update the list of files to upload.
 *
 * When a user clicks on a file selector, this function will be called.
 *
 * @param event - the event associated with selecting new files from the file selector.
 * @param setFileList - the React state setter for the list of files.
 * @param setFileInfo - the React state setter for the selected file.
 */
export async function updateFileInfoLocalList(
  event: React.ChangeEvent<HTMLInputElement>,
  setFileInfoList: React.Dispatch<React.SetStateAction<Array<FileInfo>>>,
  setFileInfo: React.Dispatch<React.SetStateAction<FileInfo | undefined>>
): Promise<Array<FileInfo>> {

  const target = event.target as HTMLInputElement;
  const fileList: Array<File> = [...target.files as FileList];
  const bufferList: Array<ArrayBuffer> = await Promise.all(fileList.map((file: File) => {
    const buffer = file.arrayBuffer();
    return buffer;
  }));
  const newFileInfoList: Array<FileInfo> = bufferList.map((buffer, index) => {
    const file = fileList[index];
    const clonedFile = new File([buffer], file.name, { type: file.type });
    const fileInfo = new FileInfo({
      name: clonedFile.name,
      location: "local",
      type: clonedFile.type,
      file: clonedFile
    });
    return fileInfo;
  });
  const newFileInfo: FileInfo = newFileInfoList[0];
  setFileInfo(prevFileInfo => (newFileInfo));
  setFileInfoList(prevFileInfo => (newFileInfoList));
  return newFileInfoList;

};


/**
 * Update the file to upload.
 *
 * When a user clicks on a file selector, this function will be called.
 *
 * @param event - the event associated with selecting new
 *  files from the file selector.
 * @param setFile - the React state
 *  setter for the selected file.
 */
export async function updateFile(
  event: React.ChangeEvent<HTMLInputElement>,
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
): Promise<void> {

  const target = event.target as HTMLInputElement;
  const fileList: Array<File> = [...target.files!];
  const file: File = fileList[0];
  const buffer = await file.arrayBuffer();
  const clonedFile = new File([buffer], file.name, { type: file.type });
  setFile(prevFile => (clonedFile));
};


/**
 * Set the file to "" when the user clicks on the file selector.
 *
 * The purpose of this is to keep the file up-to-date. Otherwise, if the user selects the
 * same file, even if the file has changed, the file won't be updated.
 *
 * @param event - the MouseEvent triggered when the user clicks on the file selector.
 */
export function setFileToNull(
  event: React.MouseEvent<HTMLInputElement>
): void {
  const target = event.target as HTMLInputElement;
  target.value = "";
}



/**
 * Update the selected file.
 *
 * @param fileInfo - the React state object containing the file.
 * @param setFileInfo - the React state setter for the selected file.
 */
async function updateSelectedFile(
  fileInfo: FileInfo,
  setFileInfo: React.Dispatch<React.SetStateAction<FileInfo | undefined>>
): Promise<void> {

  if (fileInfo.location === "storage") {
    setFileInfo(fileInfo);
  } else {
    const buffer = await fileInfo.file!.arrayBuffer();
    const clonedFile = new File([buffer], fileInfo.name, { type: fileInfo.type });
    const newFileInfo = new FileInfo({
      ...fileInfo,
      file: clonedFile
    });
    setFileInfo(prevFileInfo => (newFileInfo));
  }
};



interface FileDisplayProps {
  fileInfoList: Array<FileInfo> | undefined;
  fileInfo: FileInfo | undefined;
  setFileInfo: React.Dispatch<React.SetStateAction<FileInfo | undefined>>;
}

export function FileDisplay(
  { fileInfoList, fileInfo, setFileInfo }: FileDisplayProps
): React.ReactElement {

  return (
    <>
      {fileInfoList === undefined &&
        <>
        </>
      }
      {fileInfoList !== undefined &&
        <Container>
          <ListGroup className="no-padding file-display" defaultActiveKey="#file0">
            {fileInfoList.map((item, index) => (
              <ListGroup.Item action
                href={`#file${index}`}
                key={index}
                className="text-left"
                type="button"
                active={fileInfo !== undefined && fileInfo.name === item.name}
                onClick={() => updateSelectedFile(item, setFileInfo)}>
                {item.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>
      }
    </>
  );
}

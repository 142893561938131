import React from 'react';
import Form from 'react-bootstrap/Form';

import type {FlowFileInfo} from '../../../components/Files';
import {setFileToNull, updateFileInfoLocalList} from '../../../components/Files';
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


interface UploadFlowFileProps {
  setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>;
  setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
  setFileInfoLocalList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
}

export function UploadFlowFile({
  setFileInfo, setFileInfoDisplayList, setFileInfoLocalList
}: UploadFlowFileProps
): React.ReactElement {

  async function handleLocalFileSelector(
    event: React.ChangeEvent<HTMLInputElement>,
    setFileInfoLocalList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>,
    setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>,
    setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>
  ): Promise<void> {
    const fileInfoLocalList = await updateFileInfoLocalList(
      event as React.ChangeEvent<HTMLInputElement>,
      setFileInfoLocalList, setFileInfo
    );
    setFileInfoDisplayList(fileInfoLocalList);
  }

  return (
    <>
      <Form.Control type="file" name="filename" id="upload-flow-file-local-selector"
        onChange={(event) => handleLocalFileSelector(
          event as React.ChangeEvent<HTMLInputElement>,
          setFileInfoLocalList, setFileInfo, setFileInfoDisplayList
        )}
        onClick={(event) => setFileToNull(
          event as React.MouseEvent<HTMLInputElement, MouseEvent>
        )}
        multiple
      />
    </>
  );
}
import React from "react";
import BootstrapAlert from 'react-bootstrap/Alert';


export class AlertInfo {
  text: string;
  showAlert: boolean;
  alertType: "danger" | "warning" | "success";


  /**
   * @param text The information about the alert to be shown.
   * @param showAlert Whether or not to show the alert.
   * @param alertType The type of alert to show.
   */
  constructor(text: string, showAlert: boolean,
    alertType: "danger" | "warning" | "success"
  ) {

    this.text = text;
    this.showAlert = showAlert;
    this.alertType = alertType;
  }
}


const ALERT_NAMES = {
  "danger": "Error",
  "warning": "Warning",
  "success": "Success"
}


interface AlertProps {
  alertInfo: AlertInfo;
  setAlertInfo: React.Dispatch<React.SetStateAction<AlertInfo>>;
}

export function Alert({ alertInfo, setAlertInfo }: AlertProps) {

  if (alertInfo.showAlert) {
    return (
      <BootstrapAlert
        variant={alertInfo.alertType}
        onClose={() => setAlertInfo({ ...alertInfo, showAlert: false })}
        dismissible
      >
        <BootstrapAlert.Heading>
          {ALERT_NAMES[alertInfo.alertType]}
        </BootstrapAlert.Heading>
        <p>
          {alertInfo.text}
        </p>
      </BootstrapAlert>
    );
  } else {
    return (
      <></>
    );
  }
}

import './App.css';

import { createTheme,ThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppNavbar from "./components/AppNavbar"
import type { FlowFileInfo } from "./components/Files";
import { FlowData } from "./components/FlowData";
import AutoGate from "./pages/auto-gate/AutoGate"
import Compensation from "./pages/compensation/Compensation"
import Home from './pages/Home';
import Login from "./pages/login/Login";
import Logout from "./pages/login/Logout";
import Signup from "./pages/login/Signup"
import ManualGate from "./pages/manual-gate/ManualGate"
import { CompParams } from "./pages/manual-gate/PlotSettings";
import UserFiles from "./pages/user-files/UserFiles"


const PRIMARY_COLOR = "#39bcce";
const DEFAULT_SETTINGS = {
  compType: "metadata"
}

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: '#edf2ff',
    },
    error: {
      main: "#E6140D"
    }
  },
});

export const THEME_COLORS = {
  primary: PRIMARY_COLOR,
  darkGrey: "#686f73"
};


function App(): React.ReactElement {

  const [flowData, setFlowData] = useState(new FlowData());
  const [compParams, setCompParams] = useState(
    new CompParams({ compType: DEFAULT_SETTINGS.compType })
  );
  const [flowFileInfoStorageList, setFlowFileInfoStorageList] = useState<Array<FlowFileInfo>>([]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <AppNavbar />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/manual-gate" element={
              <ManualGate compParams={compParams} setCompParams={setCompParams}
                flowFileInfoStorageList={flowFileInfoStorageList}
                setFlowFileInfoStorageList={setFlowFileInfoStorageList}
                flowData={flowData} setFlowData={setFlowData}
              />
            }>
            </Route>
            <Route path="/auto-gate" element={<AutoGate />}></Route>
            <Route path="/compensation" element={
              <Compensation compParams={compParams} setCompParams={setCompParams}
                flowData={flowData}
              />
            }></Route>
            <Route path="/user-files" element={
              <UserFiles fileInfoStorageList={flowFileInfoStorageList}
                setFileInfoStorageList={setFlowFileInfoStorageList}
              />
            }>
            </Route>
            <Route path="/login" element={
              <Login setFlowFileInfoStorageList={setFlowFileInfoStorageList} />
            }>
            </Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="/logout" element={<Logout />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;

import React, { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';

import type { FlowData } from "../../../components/FlowData";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../../utils/Logger";
import {
  FlowPlotItem, getFlowPlotItemById, replaceItemInFlowPlotListTree,
  updateFlowPlotItemData, updateFlowPlotList
} from "../plots/FlowPlotItem";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);


const REGEX_MARKERS = [
  "CD[1-9]{1}[0-9]{0,2}[a-zA-Z0-9]{0,2}",
  "FSC-[H]",
  "FSC-[W]",
  "FSC-[A]",
  "SSC-[H]",
  "SSC-[W]",
  "SSC-[A]",
  "Time",
  "HLA(-){0,1}[A-B]\*[0-9]{2}(:[0-9]{1,3}){0,3}(:[NLSQCA]){0,1} "
]


export function matchChannel(channel: string, channelList: Array<string>): string | null {
  let matchedChannel = "";

  if (channelList.includes(channel)) {
    return channel;
  }

  for (const regex of REGEX_MARKERS) {
    const channelStem = getChannelStem(channel, regex);
    if (channelStem != null) {
      for (const newChannel of channelList) {
        const newChannelStem = getChannelStem(newChannel, regex);
        if (newChannelStem === channelStem) {
          matchedChannel = newChannel;
          return matchedChannel;
        }
      }
    }
  }

  return null;
}


function getChannelStem(channel: string, regex: string): string | null {
  const channelStem = channel.match(regex);
  if (channelStem != null) {
    return channelStem[0];
  } 
    return null;
  
}


interface ChannelDropdownProps {
  col: number;
  flowPlotItem: FlowPlotItem;
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  flowData: FlowData;
  dropdownType: "channelTable" | "plot";
}

export const ChannelDropdown = forwardRef(
  function ChannelDropdown(
    { col, flowPlotItem, flowPlotList, setFlowPlotList, flowData,
      dropdownType="channelTable" }: ChannelDropdownProps,
    ref: React.ForwardedRef<Array<FlowPlotItem>>
) {

  let selectedChannel = "";
  let variableName = "";
  if (col === 0) {
    selectedChannel = flowPlotItem.channelX;
    variableName = "x";
  } else {
    selectedChannel = flowPlotItem.channelY;
    variableName = "y";
  }

  const id = `channel-dropdown-${  variableName  }-${  dropdownType  }-${  flowPlotItem.id}`;
  let className = "";
  if (dropdownType === "plot") {
    className = `dropdown-${  variableName}`;
  }

  return (
    <>
      <Form.Select
        aria-label="Channel selection dropdown"
        className={className}
        id={id}
        onChange={(event) => updateChannel(
          event, col, flowPlotItem.id, flowPlotList, setFlowPlotList, flowData,
          ref as React.MutableRefObject<Array<FlowPlotItem>>
        )}
        value={selectedChannel}
      >
        {flowData.channels.map((channel, index) => (
          <option value={channel} key={index}>{channel}</option>
        ))}
      </Form.Select>
    </>
  );
});


/**
 * Update the channel when selected from dropdown.
 *
 * This function is called when a user selects a channel from the dropdown menu in a given row
 * of the ChannelTable.
 * 1. Update either `channelX` or `channelY` in the given `flowPlotItem`.
 * 2. Fetch the data from the server for the `flowPlotItem` for the new channel.
 * 3. Propogate the changes to the entire `flowPlotList` state object via
 *  `replaceItemInFlowPlotListTree`.
 *
 * @param event - a dropdown select event.
 * @param col - the column, either 0 or 1, where 0 = channelX and 1 = channelY.
 * @param flowPlotItem - the flow plot that coincides with the selected dropdown.
 * @param flowPlotList - the main list of all flow plots.
 * @param setFlowPlotList - the React setter function for the State object flowPlotList.
 * @param flowData - the main object containing the data for the *.fcs file.
 * @param flowPlotListRef - ref for flowPlotList.
 */
async function updateChannel(
  event: React.ChangeEvent<HTMLSelectElement>,
  col: number,
  flowPlotItemId: string,
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  flowData: FlowData,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>
): Promise<void> {

  const flowPlotItem = getFlowPlotItemById(flowPlotItemId, flowPlotListRef.current)[1];

  logger.info(
    "updateChannel",
    "flowPlotItem",
    flowPlotItem
  )();

  let newFlowPlotItem = new FlowPlotItem({
    ...flowPlotItem.toObject()
  });

  logger.info(
    "updateChannel",
    "flowPlotItem",
    newFlowPlotItem
  )();

  logger.info(
    "updateChannel",
    "flowPlotListRef",
    flowPlotListRef.current
  )();

  logger.info(
    "updateChannel",
    "flowPlotList",
    flowPlotList
  )();

  if (col === 0) {
    newFlowPlotItem.channelX = event.target.value;
  } else {
    newFlowPlotItem.channelY = event.target.value;
  }
  logger.info(
    "updateChannel",
    "Updating channel to: ",
    event.target.value
  )();
  newFlowPlotItem = await updateFlowPlotItemData(
    newFlowPlotItem, flowPlotListRef.current, flowData
  );
  logger.info(
    "updateChannel",
    "updated data from the backend: ",
    newFlowPlotItem
  )();
  const newFlowPlotList = replaceItemInFlowPlotListTree(newFlowPlotItem, flowPlotListRef.current);
  logger.info(
    "updateChannel",
    "updating flowPlotList and flowPlotListRef to: ",
    newFlowPlotList
  )();
  updateFlowPlotList(newFlowPlotList, setFlowPlotList, flowPlotListRef);
};

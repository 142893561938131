import { Progress } from "../../components/Buttons";
import type { FlowFileInfo } from "../../components/Files";
import { FlowData } from "../../components/FlowData";
import type { QuadrantInfo } from "../../components/plots/Quadrants";
import { ClusterInfo, updateClusterInfo } from "../../pages/manual-gate/Clusters";
import { UMAP_HEIGHT,UMAP_MARGIN, UMAP_WIDTH } from "../../pages/manual-gate/ManualGate";
import {
  FlowPlotItem, updateFlowPlotList, updateFlowPlotListDataTree
} from "../../pages/manual-gate/plots/FlowPlotItem";
import type { CompParams,TransParams } from "../../pages/manual-gate/PlotSettings";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import { ApiError,apiPost } from "../Api";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);

const MAX_NUM_CHUNKS_PER_BATCH = 5; // maximum number of chunks for loading data



/**
 * Load data from an *.fcs file on the backend.
 *
 * This function is called when the file is changed (see UploadFlowFile) or when a user selects the
 * clustering option (see PlotSettings).
 * A new flowData object will be created.
 *
 * @param fileInfo - the object containing information about the FCS file to load data from.
 * @param numCells - the number of cells to load from the *.fcs file.
 * @param setFlowData - the React state setter for flowData.
 * @param flowPlotList - the main list of FlowPlotItems.
 * @param setFlowPlotList - the React state setter for flowPlotList.
 * @param setUmapFlowPlotItem - the React state setter for the umapFlowPlotItem.
 * @param setIsLoading - the React state setter for the isLoading object.
 * @param transParams - the parameters for the transformation of the *.fcs data.
 * @param setClusterInfo - the React state setter for clusterInfo.
 * @param flowPlotListRef - the main ref list of FlowPlotItems.
 * @param reloadFlowPlotList - if true, get all new data from the backend. If false,
 *  allow `updateFlowPlotListDataTree` to check if the data already exists in the list and simply
 *  copy it.
 */
export async function getFlowDataFromFirestore(
  fileInfo: FlowFileInfo,
  numCells: number,
  setFlowData: React.Dispatch<React.SetStateAction<FlowData>>,
  flowPlotList: Array<FlowPlotItem>,
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>,
  setUmapFlowPlotItem: React.Dispatch<React.SetStateAction<FlowPlotItem>>,
  compParams: CompParams,
  transParams: TransParams,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>,
  flowPlotListRef: React.MutableRefObject<Array<FlowPlotItem>>,
  progress: Progress,
  setProgress: React.Dispatch<React.SetStateAction<Progress>>,
  setDataIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  reloadFlowPlotList = false
): Promise<void> {

  if (setDataIsLoading !== undefined) {
    setDataIsLoading(true);
  }

  logger.info(
    "loadData",
    "api",
    "Loading data from file: ",
    fileInfo.name,
    "\ntransParams:\n",
    JSON.stringify(transParams)
  )();


  try {
    const totalEvents = fileInfo.totalEvents;
    const numEventsPerChunk = fileInfo.numEventsPerChunk;
    const numEventsPerChunkHTTP = fileInfo.numEventsPerChunkHTTP;

    if (totalEvents === undefined || numEventsPerChunk === undefined 
        || numEventsPerChunkHTTP === undefined) {
      throw new ApiError("fileInfo is missing chunk metadata.");
    }

    const totalChunks = Math.ceil(numCells / numEventsPerChunk);

    const numChunksPerBatch = Math.min(
      MAX_NUM_CHUNKS_PER_BATCH,
      Math.floor(numEventsPerChunkHTTP / numEventsPerChunk),
      totalChunks
    );
    const totalBatches = Math.ceil(totalChunks / numChunksPerBatch);
    const batches = [...Array(totalBatches).keys()];

    logger.info(
      "loadData",
      "api",
      `totalBatches: ${totalBatches}\n`,
      `numChunksPerBatch: ${numChunksPerBatch}\n`,
      `totalChunks: ${totalChunks}\n`,
      `numEventsPerChunk: ${numEventsPerChunk}`
    )();

    let indicesList: Array<Record<string, Array<number> | number>> = [];
    let startChunk = 0;
    let endChunk = startChunk + numChunksPerBatch;

    for (let batchNumber = 0; batchNumber < totalBatches; batchNumber++) {
      const chunks: Array<number> = Array(endChunk - startChunk).fill(0).map((x, y) => x + y);
      indicesList = [...indicesList,
      {
        indexOffset: startChunk * numEventsPerChunk,
        endIndex: batchNumber === totalBatches - 1
          ? numCells - batchNumber * numEventsPerChunk * numChunksPerBatch
          : numEventsPerChunk * numChunksPerBatch,
        chunks: chunks
      }
      ];
      startChunk += numChunksPerBatch;
      endChunk = batchNumber === totalBatches - 2 ? totalChunks : startChunk + numChunksPerBatch;
    }

    const progressIncrement = 1 / totalBatches;
    const progressInterval = progress.end - progress.start;

    const resultsList = await Promise.all(batches.map(async (batchNumber: number) => {
      const chunks = indicesList[batchNumber].chunks as Array<number>;
      const indexOffset = indicesList[batchNumber].indexOffset as number;
      const endIndex = indicesList[batchNumber].endIndex as number;
      const result = await getFlowDataFromFirestoreBatch(
        fileInfo.name, chunks, endIndex, indexOffset, compParams, transParams
      );
      progress = {
        ...progress,
        value: progress.value + progressInterval * progressIncrement
      };
      setProgress(progress);
      return result;
    }));


    const eventsList = resultsList.map((item) => {
      return item.events;
    });
    const layersList = resultsList.map((item) => {
      return item.layers;
    });
    const channelsList = resultsList.map((item) => {
      return item.channels;
    })
    const channels = channelsList[0];
    logger.info(
      "loadData",
      "api",
      "eventsList",
      eventsList,
      "layersList",
      layersList
    )();
    const events: Record<string, Array<number>> = {};
    const layers: Record<string, Array<number>> = {};
    Object.keys(eventsList[0]).forEach((key) => {
      events[key] = [];
    });
    Object.keys(layersList[0]).forEach((key) => {
      layers[key] = [];
    });

    for (let batchNumber = 0; batchNumber < totalBatches; batchNumber++) {
      const eventsBatch: Record<string, Array<number>> = eventsList[batchNumber];
      const layersBatch: Record<string, Array<number>> = layersList[batchNumber];
      logger.info(
        "loadData",
        "api",
        "eventsBatch",
        eventsBatch,
        "events",
        events
      )();
      Object.entries(eventsBatch).forEach(([key, value]) => {
        events[key] = [...events[key], ...value];
      });
      Object.entries(layersBatch).forEach(([key, value]) => {
        layers[key] = [...layers[key], ...value];
      });
    }

    const flowData = new FlowData();
    flowData.fileName = fileInfo.name;
    flowData.numCells = numCells;
    flowData.channels = channels;
    flowData.maxNumCells = totalEvents;
    flowData.numEventsPerChunk = numEventsPerChunk;
    flowData.totalChunks = totalChunks;

    Object.entries(events).forEach(([key, value]) => {
      flowData.events[key] = value;
    });
    Object.entries(layers).forEach(([key, value]) => {
      flowData.layers[key] = value;
    });


    logger.info(
      "loadData",
      "api",
      "flowPlotList",
      flowPlotList
    )();

    logger.info(
      "loadData",
      "api",
      "flowData",
      flowData
    )();


    let newFlowPlotList = [
      ...flowPlotList
    ]

    if (flowPlotList.length === 1 && flowPlotList[0].channelX === "") {
      const newFlowPlotItem = new FlowPlotItem({});
      newFlowPlotItem.channelX = flowData.channels[0];
      newFlowPlotItem.channelY = flowData.channels[1];
      newFlowPlotList = [
        newFlowPlotItem
      ];
    }

    newFlowPlotList = newFlowPlotList.map((flowPlotItem) => {
      flowPlotItem.numCells = numCells;
      return flowPlotItem;
    });

    logger.info(
      "loadData",
      "api",
      "flowPlotList",
      newFlowPlotList
    )();

    const clusterInfo = new ClusterInfo({});
    updateClusterInfo(flowData, clusterInfo, setClusterInfo);

    newFlowPlotList = await updateFlowPlotListDataTree(
      newFlowPlotList, flowData, reloadFlowPlotList
    );
    updateFlowPlotList(newFlowPlotList, setFlowPlotList, flowPlotListRef);
    logger.info(
      "loadData",
      "api",
      "flowPlotListRef: ",
      flowPlotListRef.current
    )();
    setFlowData(flowData);

    const newUmapFlowPlotItem = new FlowPlotItem({
      x: events.umap_x.slice(0, numCells),
      y: events.umap_y.slice(0, numCells),
      clusters: flowData.events.cluster,
      layers: flowData.layers,
      margin: UMAP_MARGIN,
      width: UMAP_WIDTH,
      height: UMAP_HEIGHT
    });
    setUmapFlowPlotItem(newUmapFlowPlotItem);

  } catch (error) {
    logger.error(
      "loadData",
      "Error while loading data from file: ",
      fileInfo.name,
      error
    )();
    throw new ApiError(
      `Error loading data from ${fileInfo.name}`
    );
  } finally {
    setProgress(new Progress(progress.end, progress.start, progress.end));
    if (setDataIsLoading !== undefined) {
      setDataIsLoading(false);
    }
  }
};


/**
 * Load a chunk of data from an *.fcs file on the backend.
 *
 * Firebase Cloud Functions have a maximum limit of 32Mb for data transfer in an HTTP request.
 * As a result, the flow data from a large file needs to be split into smaller chunks.
 *
 * @param fileName - the file name to load data from (must be *.fcs).
 * @param numEvents - the number of events to load in a single chunk.
 * @param chunkNumber - the index of the chunk, out of the total number of chunks.
 */
async function getFlowDataFromFirestoreBatch(
  fileName: string, chunks: Array<number>, endIndex: number, indexOffset: number,
  compParams: CompParams, transParams: TransParams
): Promise<Record<string, any>> {

  const events: Record<string, Array<number>> = {};
  const layers: Record<string, Array<number>> = {};
  let channels: Array<string> = [];
  try {
    const formData = new FormData();
    formData.append("fileName", JSON.stringify(fileName));
    formData.append("chunks", JSON.stringify(chunks));
    formData.append("transParams", JSON.stringify(transParams));
    formData.append("indexOffset", JSON.stringify(indexOffset));
    formData.append("endIndex", JSON.stringify(endIndex));

    if (compParams.compType === "csv") {
      formData.append("spilloverFile", compParams.spilloverFile!);
      formData.append("compParams", JSON.stringify({ compType: "csv" }));
    } else {
      formData.append("compParams", JSON.stringify(compParams));
    }

    logger.info(
      "loadFlowDataBatch",
      "api",
      "Loading data from file: ",
      fileName
    )();
    const urlSuffix = "/manual_gate_load_fcs_data_batch";
    const responseData = await apiPost(urlSuffix, formData);
    logger.info(
      "loadFlowDataBatch",
      "files",
      "responseData",
      responseData
    )();

    channels = JSON.parse(responseData.channels);

    Object.entries(responseData.events).forEach(([key, value]) => {
      events[key] = JSON.parse(value as string);
    });
    Object.entries(responseData.layers).forEach(([key, value]) => {
      layers[key] = JSON.parse(value as string);
    });
    return {
      events: events,
      layers: layers,
      channels: channels
    };
  } catch (error) {
    logger.error(
      "loadFlowDataBatch",
      error
    )();
    return {
      events: events,
      layers: layers,
      channels: channels
    };
  }
}



export async function getPlotData(
  x: Array<number>, y: Array<number>,
  channelX: string, channelY: string, indices: Array<number>,
  xMin?: number, xMax?: number, yMin?: number, yMax?: number
): Promise<[Array<number>, Array<number>, Array<number>, number, number]> {

  let newX = [0];
  let newY = [0];
  let density = [0];
  let maxDensity = 1;
  let minDensity = 0;
  logger.info(
    "getPlotData",
    "api",
    "Getting density data from backend manual-gate-get-plot-data"
  )();

  const urlSuffix = "/manual_gate_get_plot_data";
  const formData = new FormData();
  formData.append("x", JSON.stringify(x));
  formData.append("y", JSON.stringify(y));
  formData.append('channelX', channelX);
  formData.append('channelY', channelY);
  formData.append("indices", JSON.stringify(indices));
  if (xMin !== undefined) {
    formData.append("xMin", JSON.stringify(xMin));
  }
  if (xMax !== undefined) {
    formData.append("xMax", JSON.stringify(xMax));
  }
  if (yMin !== undefined) {
    formData.append("yMin", JSON.stringify(yMin));
  }
  if (yMax !== undefined) {
    formData.append("yMax", JSON.stringify(yMax));
  }

  try {
    const start = Date.now();
    const responseData = await apiPost(urlSuffix, formData);
    logger.info(
      "getPlotData",
      "api",
      "response: ",
      responseData
    )();

    newX = JSON.parse(responseData.x);
    newY = JSON.parse(responseData.y);
    density = JSON.parse(responseData.density);
    maxDensity = JSON.parse(responseData.maxDensity);
    minDensity = JSON.parse(responseData.minDensity);
    const end = Date.now();
    const timeElapsed = end - start;
    logger.info(
      "getPlotData",
      "api",
      `time elapsed: ${timeElapsed / 1000} seconds`
    )();
    return [newX, newY, density, maxDensity, minDensity];
  } catch (error) {
    logger.error(
      "getPlotData",
      "Error while getting plot data: ",
      error
    )();
    return [newX, newY, density, maxDensity, minDensity];
  }
};


export async function getDataInQuadrant(x: Array<number>, y: Array<number>, density: Array<number>,
  clusters: Array<number>, quadrantInfo: QuadrantInfo, xScale: d3.ScaleLinear<number, number>,
  yScale: d3.ScaleLinear<number, number>):
  Promise<[Array<number>, Array<number>, Array<number>, Array<number>,
    Record<string, Array<number>>, Array<number>]> {

  const xLine = xScale.invert(quadrantInfo.verticalLine.x1 - quadrantInfo.margin.left);
  const yLine = yScale.invert(quadrantInfo.horizontalLine.y1 - quadrantInfo.margin.top);
  let newX = [0];
  let newY = [0];
  let newDensity = [0];
  let newClusters = [0];
  let newLayers: Record<string, Array<number>> = {};
  let newIndices = [0];

  const urlSuffix = "/manual_gate_subset_data";
  logger.info(
    "getDataInQuadrant",
    "quadrants",
    "Getting subset of data from backend: ",
    urlSuffix
  )();
  const formData = new FormData();

  formData.append('xLine', String(xLine));
  formData.append('yLine', String(yLine));
  formData.append("x", JSON.stringify(x));
  formData.append("y", JSON.stringify(y));
  formData.append("density", JSON.stringify(density));
  formData.append("clusters", JSON.stringify(clusters));
  formData.append("quadrantId", String(quadrantInfo.quadrantId));
  logger.info(
    "getDataInQuadrant",
    "quadrants",
    "x: ",
    x
  )();
  logger.info(
    "getDataInQuadrant",
    "quadrants",
    "quadrantId: ",
    quadrantInfo.quadrantId
  )();
  logger.info(
    "getDataInQuadrant",
    "quadrants",
    "xLine: ",
    xLine
  )();

  try {
    const start = Date.now();
    const responseData = await apiPost(urlSuffix, formData);
    logger.info(
      "getDataInQuadrant",
      "quadrants",
      "Response from backend: ",
      responseData
    )();
    newX = JSON.parse(responseData.x);
    newY = JSON.parse(responseData.y);
    newDensity = JSON.parse(responseData.density);
    newClusters = JSON.parse(responseData.clusters);
    newIndices = JSON.parse(responseData.indices);

    const layers: Record<string, string> = responseData.layers;
    newLayers = {};
    Object.entries(layers).forEach(([key, value]) => {
      newLayers[key] = JSON.parse(value);
    });

    const end = Date.now();
    const timeElapsed = end - start;
    logger.info(
      "getDataInQuadrant",
      "quadrants",
      `time to fetch data: ${timeElapsed / 1000} seconds`
    )();
    return [newX, newY, newDensity, newClusters, newLayers, newIndices];
  } catch (error) {
    logger.error(
      "getDataInQuadrant",
      "error fetching data from backend: ",
      error
    )();
    return [newX, newY, newDensity, newClusters, newLayers, newIndices];
  }
}

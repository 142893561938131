import React, { forwardRef,useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { ApiError } from "../../api/Api";
import { getChunkMetadata } from "../../api/FlowFiles";
import { getFlowDataFromFirestore } from "../../api/manual-gate/LoadData";
import { Alert, AlertInfo } from "../../components/Alerts";
import { InfoTooltip, Progress,ProgressButton } from '../../components/Buttons';
import { CollapseIcon } from '../../components/Collapsibles';
import { FlowFileInfo,setFileToNull, updateFileInfoInList } from "../../components/Files";
import type { FlowData } from "../../components/FlowData";
import { ConsoleLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import type { SpilloverMatrix } from "../compensation/Compensation";
import type { ClusterInfo } from "./Clusters";
import type { FlowPlotItem } from "./plots/FlowPlotItem";

const logger = new ConsoleLogger(LOG_LEVEL, LOG_FILTERS);

interface TransConstructorParams {
  transType: string;
  parameters?: Record<string, number>;
}

export class TransParams {
  transType = "none";
  parameters: Record<string, number> = {};

  constructor({ transType = "none", parameters = {} }: TransConstructorParams) {

    this.transType = transType;
    this.parameters = parameters;
  }
}

interface CompConstructorParams {
  compType: string;
  spilloverMatrix?: SpilloverMatrix;
  spilloverFile?: File;
}

export class CompParams {
  compType = "none";
  spilloverMatrix?: SpilloverMatrix;
  spilloverFile?: File;

  constructor({
    compType = "metadata", spilloverMatrix = undefined, spilloverFile = undefined
  }: CompConstructorParams) {
    this.compType = compType;
    this.spilloverMatrix = spilloverMatrix;
    this.spilloverFile = spilloverFile;
  }
}


const ASINH_PARAMS = {
  "t": 1024,
  "m": 2,
  "a": 0
}

export const LOGICLE_PARAMS = {
  "t": 262144,
  "m": 4.5,
  "a": 0,
  "w": 1.0
}

const PLOT_TYPE_OPTIONS = [
  { value: "density", label: "Density" },
  { value: "clusters", label: "Clusters" }
]

const LAYOUT_TYPE_OPTIONS = [
  { value: "flat", label: "Flat" },
  { value: "hierarchical", label: "Hierarchical" }
]


interface PlotSettingsProps {
  plotType: string;
  setPlotType: React.Dispatch<React.SetStateAction<string>>;
  layoutType: "flat" | "hierarchical";
  setLayoutType: React.Dispatch<React.SetStateAction<"flat" | "hierarchical">>;
  compParams: CompParams;
  setCompParams: React.Dispatch<React.SetStateAction<CompParams>>;
  transParams: TransParams;
  setTransParams: React.Dispatch<React.SetStateAction<TransParams>>;
  numCells: number;
  setNumCells: React.Dispatch<React.SetStateAction<number>>;
  fileInfo: FlowFileInfo | undefined;
  setFileInfo: React.Dispatch<React.SetStateAction<FlowFileInfo | undefined>>;
  fileInfoStorageList: Array<FlowFileInfo>;
  setFileInfoStorageList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
  fileInfoDisplayList: Array<FlowFileInfo>;
  setFileInfoDisplayList: React.Dispatch<React.SetStateAction<Array<FlowFileInfo>>>;
  flowData: FlowData;
  setFlowData: React.Dispatch<React.SetStateAction<FlowData>>;
  flowPlotList: Array<FlowPlotItem>;
  setFlowPlotList: React.Dispatch<React.SetStateAction<Array<FlowPlotItem>>>;
  setUmapFlowPlotItem: React.Dispatch<React.SetStateAction<FlowPlotItem>>;
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>;
  setDataIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PlotSettings = forwardRef(function PlotSettings(
  { plotType, setPlotType, layoutType, setLayoutType, compParams, setCompParams,
    transParams, setTransParams, numCells, setNumCells, fileInfo, setFileInfo,
    fileInfoStorageList, setFileInfoStorageList, fileInfoDisplayList, setFileInfoDisplayList,
    flowData, setFlowData, flowPlotList, setFlowPlotList, setUmapFlowPlotItem,
    setClusterInfo, setDataIsLoading }: PlotSettingsProps,
  ref: React.ForwardedRef<Array<FlowPlotItem>>) {

  const [isLoading, setIsLoading] = useState(false);
  const [showPlotSettings, setShowPlotSettings] = useState(true);
  const [progress, setProgress] = useState(new Progress(0));
  const [alertInfo, setAlertInfo] = useState(new AlertInfo("", false, "danger"));

  async function updatePlots(
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault();
    if (fileInfo === undefined) {
      logger.error(
        "updatePlots",
        "File is undefined"
      )();
    } else {
      logger.info(
        "updatePlots",
        "plot settings",
        "Loading new data from the backend...",
        (ref as React.MutableRefObject<Array<FlowPlotItem>>)!.current!
      )();

      setIsLoading(true);
      setProgress(new Progress(5, 5, 100));
      try {
        let newFileInfo = new FlowFileInfo({ ...fileInfo });
        if (fileInfo.totalEvents === undefined || fileInfo.numEventsPerChunk === undefined 
          || fileInfo.numEventsPerChunkHTTP === undefined) {
            newFileInfo = await getChunkMetadata(fileInfo);
            setFileInfo(newFileInfo);
            updateFileInfoInList(newFileInfo, fileInfoStorageList, setFileInfoStorageList);
            updateFileInfoInList(newFileInfo, fileInfoDisplayList, setFileInfoDisplayList);
        }
        await getFlowDataFromFirestore(
          newFileInfo, numCells, setFlowData,
          flowPlotList, setFlowPlotList, setUmapFlowPlotItem,
          compParams, transParams, setClusterInfo,
          ref as React.MutableRefObject<Array<FlowPlotItem>>,
          new Progress(5, 5, 100), setProgress, setDataIsLoading
        );
      } catch (error) {
        if (error instanceof ApiError) {
          setAlertInfo(new AlertInfo(error.message, true, "danger"));
        }
      } finally {
        await new Promise(r => setTimeout(r, 800));
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      {showPlotSettings &&
        <Card>
          <Card.Header as="h5" className="expanded">
            Plot Settings
            <CollapseIcon showComponent={showPlotSettings} setShowComponent={setShowPlotSettings} />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={updatePlots}>
              <Row>
                <Col className="col-8 offset-2">
                  <PlotTypeSelector plotType={plotType} setPlotType={setPlotType} />
                </Col>
                <Col className="col-1">
                  <InfoTooltip
                    text="The clusters plots will show the data coloured by their respective
              clusters. The density plots will show the data coloured by the 2D density."
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-8 offset-2">
                  <LayoutTypeSelector layoutType={layoutType} setLayoutType={setLayoutType} />
                </Col>
                <Col className="col-1">
                  <InfoTooltip text="This will determine the layout of the plots on the right." />
                </Col>
              </Row>
              <Row>
                <Col className="col-8 offset-2">
                  <CompensationSelector compParams={compParams} setCompParams={setCompParams} />
                </Col>
                <Col className="col-1">
                  <InfoTooltip text="If you would like to compensate your *.fcs data, select
                  one of the compensation options below. Otherwise, set to none."/>
                </Col>
              </Row>
              <Row>
                <Col className="col-10 offset-1">
                  <CompensationOptions compParams={compParams} setCompParams={setCompParams} />
                </Col>
              </Row>
              <Row>
                <Col className="col-8 offset-2">
                  <TransformationSelector transParams={transParams} setTransParams={setTransParams}
                  />
                </Col>
                <Col className="col-1">
                  <InfoTooltip text="If you would like to transform your *.fcs data, select
              one of the transformation options below. Otherwise, set to none."/>
                </Col>
              </Row>
              <Row>
                <Col className="col-10">
                  <TransformationOptions transParams={transParams} setTransParams={setTransParams}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="col-8 offset-2">
                  <NumCellsSelector numCells={numCells} setNumCells={setNumCells} />
                </Col>
                <Col className="col-1">
                  <InfoTooltip text={`Must be an integer between 0 and ${flowData.maxNumCells}.`} />
                </Col>
              </Row>
              <ProgressButton buttonTitle="Update Plots" isLoading={isLoading} progress={progress}
                colSizeButton={6} offsetButton={3}
              />
              <Row>
                <Alert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
              </Row>
            </Form>
          </Card.Body>
        </Card>
      }
      {!showPlotSettings &&
        <Card>
          <Card.Header as="h5" className="collapsed">
            Plot Settings
            <CollapseIcon showComponent={showPlotSettings} setShowComponent={setShowPlotSettings} />
          </Card.Header>
        </Card>
      }
    </>
  );
});


interface PlotTypeSelectorProps {
  plotType: string;
  setPlotType: React.Dispatch<React.SetStateAction<string>>;
}

export function PlotTypeSelector(
  { plotType, setPlotType }: PlotTypeSelectorProps
): React.ReactElement {

  function updatePlotType(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    setPlotType(event.target.value);
  };


  return (
    <>
      <Form.Group controlId="plotTypeSelector" className="mb-3">
        <Form.Label>Plot Type</Form.Label>
        <Form.Select
          defaultValue={plotType}
          onChange={(event) => updatePlotType(event)}
        >
          {PLOT_TYPE_OPTIONS.map((plotTypeOption) => {
            return <option key={plotTypeOption.label} value={plotTypeOption.value}>
              {plotTypeOption.label}
              </option>;
          })}
        </Form.Select>
      </Form.Group>
    </>
  );
}


interface LayoutTypeSelectorProps {
  layoutType: "flat" | "hierarchical";
  setLayoutType: React.Dispatch<React.SetStateAction<"flat" | "hierarchical">>;
}

function LayoutTypeSelector(
  { layoutType, setLayoutType }: LayoutTypeSelectorProps
): React.ReactElement {

  function updateLayoutType(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    const value = event.target.value as "flat" | "hierarchical";
    setLayoutType(value);
  };

  return (
    <>
      <fieldset>
        <Form.Group controlId="layoutTypeSelector" className="mb-3">
          <Form.Label as="legend">Layout Type</Form.Label>
          <Row>
            {LAYOUT_TYPE_OPTIONS.map((option) => (
              <Col className="col-4" key={option.label}>
                <Form.Check
                  inline
                  label={option.label}
                  name="group1"
                  type={"radio"}
                  id={`layout-radio-${option.value}`}
                  value={option.value}
                  checked={layoutType === option.value}
                  onChange={(event) => updateLayoutType(event)}
                />
              </Col>
            ))}
          </Row>
        </Form.Group>
      </fieldset>
    </>
  );
}


interface NumCellsSelectorProps {
  numCells: number;
  setNumCells: React.Dispatch<React.SetStateAction<number>>;
}

function NumCellsSelector(
  { numCells, setNumCells }: NumCellsSelectorProps
): React.ReactElement {

  function updateNumCells(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    let newNumCells = parseInt(event.target.value);
    if (isNaN(newNumCells)) {
      newNumCells = 1000;
    }
    setNumCells(newNumCells);
  };

  return (
    <>
      <Form.Group controlId="numCellsSelector" className="mb-3">
        <Form.Label>Number of Cells</Form.Label>
        <Form.Control
          type="text"
          defaultValue={numCells}
          onChange={(event) => updateNumCells(event as React.ChangeEvent<HTMLInputElement>)}
        />
      </Form.Group>
    </>
  );
}

interface CompensationSelectorProps {
  compParams: CompParams;
  setCompParams: React.Dispatch<React.SetStateAction<CompParams>>;
}

function CompensationSelector(
  { compParams, setCompParams }: CompensationSelectorProps
): React.ReactElement {

  function updateCompType(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    const compType = event.target.value;
    const newCompParams = { ...compParams };
    newCompParams.compType = compType;
    setCompParams(newCompParams);
  };

  return (
    <>
      <Form.Group controlId="compensationSelector" className="mb-3">
        <Form.Label>Compensation</Form.Label>
        <Form.Select aria-label="Compensation selector" defaultValue={compParams.compType}
          onChange={(event) => updateCompType(event)}>
          <option value="none">none</option>
          <option value="metadata">FCS file metadata</option>
          <option value="matrix" disabled={true}>spillover matrix</option>
          <option value="csv">CSV</option>
        </Form.Select>
      </Form.Group>
    </>
  );
}

interface CompensationOptionsProps {
  compParams: CompParams;
  setCompParams: React.Dispatch<React.SetStateAction<CompParams>>;
}

function CompensationOptions(
  { compParams, setCompParams }: CompensationOptionsProps
): React.ReactElement {

  async function handleFileSelector(
    event: React.ChangeEvent<HTMLInputElement>,
    compParams: CompParams,
    setCompParams: React.Dispatch<React.SetStateAction<CompParams>>
  ): Promise<void> {
    const target = event.target as HTMLInputElement;
    const fileList: Array<File> = [...target.files as FileList];
    const file: File = fileList[0];
    const buffer = await file.arrayBuffer();
    const clonedFile = new File([buffer], file.name, { type: file.type });
    setCompParams({
      ...compParams,
      spilloverFile: clonedFile
    });
  }

  if (compParams.compType === "none" || compParams.compType === "metadata") {
    return (
      <>
      </>
    );
  } else if (compParams.compType === "matrix") {
    return (
      <>
      </>
    );
  } else if (compParams.compType === "csv") {
    return (
      <Form>
        <Form.Group controlId="spilloverFileSelector" className="mb-3">
          <label htmlFor="spillover-file-selector" className="form-label">
            Select a file from your computer:
          </label>
          <Row>
            <Form.Control type="file" name="filename" id="spillover-file-selector"
              onChange={(event) => handleFileSelector(
                event as React.ChangeEvent<HTMLInputElement>,
                compParams, setCompParams
              )}
              onClick={(event) => setFileToNull(
                event as React.MouseEvent<HTMLInputElement, MouseEvent>
              )}
            />
          </Row>
        </Form.Group>
      </Form >
    )
  } else {
    return (
      <>
      </>
    );
  }
}

interface TransformationSelectorProps {
  transParams: TransParams;
  setTransParams: React.Dispatch<React.SetStateAction<TransParams>>;
}

function TransformationSelector(
  { transParams, setTransParams }: TransformationSelectorProps
): React.ReactElement {

  function updateTransType(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    const transType = event.target.value;
    const newTransParams = { ...transParams };
    if (transType === "asinh") {
      newTransParams.parameters = ASINH_PARAMS;
    } else if (transType === "logicle") {
      newTransParams.parameters = LOGICLE_PARAMS;
    }
    newTransParams.transType = transType;
    setTransParams(newTransParams);
  };

  return (
    <>
      <Form.Group controlId="transformationSelector" className="mb-3">
        <Form.Label>Transformation</Form.Label>
        <Form.Select aria-label="Transformation selector" defaultValue={transParams.transType}
          onChange={(event) => updateTransType(event)}>
          <option value="none">none</option>
          <option value="logicle">logicle</option>
          <option value="asinh">asinh</option>
        </Form.Select>
      </Form.Group>
    </>
  );
}

interface TransformationOptionsProps {
  transParams: TransParams;
  setTransParams: React.Dispatch<React.SetStateAction<TransParams>>;
}

function TransformationOptions(
  { transParams, setTransParams }: TransformationOptionsProps
): React.ReactElement {

  function updateParams(
    event: React.ChangeEvent<HTMLInputElement>, key: string
  ): void {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      transParams.parameters[key] = value;
      setTransParams(transParams);
    }
  };

  if (transParams.transType === "none") {
    return (
      <>
      </>
    );
  } else {
    return (
      <>
        {Object.keys(transParams.parameters).map(key =>
          <Row key={key}>
            <Col className="col-2">
              <p className="plot-settings-label">{key}:</p>
            </Col>
            <Col className="col-10">
              <Form.Group controlId="transformationOptions" className="mb-3">
                <Form.Control
                  type="text"
                  defaultValue={transParams.parameters[key]}
                  onChange={
                    (event) => updateParams(event as React.ChangeEvent<HTMLInputElement>, key)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )
        }
      </>
    );
  }

}

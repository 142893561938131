import { AlertInfo } from "../../components/Alerts";
import type {ClusterInfo } from "../../pages/manual-gate/Clusters";
import { CELL_TYPES } from "../../pages/manual-gate/Clusters";
import { FlowPlotLogger, LOG_FILTERS,LOG_LEVEL } from "../../utils/Logger";
import { apiPost } from "../Api";


const logger = new FlowPlotLogger(LOG_LEVEL, LOG_FILTERS);


export async function loadAnnotations(
  event: React.FormEvent<HTMLFormElement>,
  file: File | undefined,
  clusterInfo: ClusterInfo,
  setClusterInfo: React.Dispatch<React.SetStateAction<ClusterInfo>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setAlertInfo: React.Dispatch<React.SetStateAction<AlertInfo>>
): Promise<void> {

  event.preventDefault();
  if (file === undefined) {
    logger.error(
      "loadAnnotations",
      "File is undefined"
    )();
    return;
  }
  setIsLoading(true);
  const urlSuffix = "/manual_gate_load_annotations";
  logger.info(
    "loadAnnotations",
    "annotation",
    "Loading annotations: ",
    urlSuffix
  )();

  const formData = new FormData();

  formData.append("file", file);
  formData.append("fileName", file.name);

  try {
    const responseData = await apiPost(urlSuffix, formData);
    logger.info(
      "loadAnnotations",
      "annotation",
      "response: ",
      responseData
    )();
    const newClusterInfo = JSON.parse(responseData.clusterInfo);
    logger.info(
      "loadGatingScheme",
      "annotation",
      "clusterInfo: ",
      newClusterInfo
    )();
    for (const label of newClusterInfo.labels) {
      if (!CELL_TYPES.includes(label)) {
        throw new Error(`${label} is not a valid annotation, must be one of ${CELL_TYPES.join('\r\n')}`)
      }
    }
    setClusterInfo({
      ...clusterInfo,
      labels: newClusterInfo.labels,
      ids: newClusterInfo.ids
    });
  } catch (error) {
    logger.error(
      "loadAnnotations",
      "error loading annotations: ",
      error
    )();
    setAlertInfo(new AlertInfo(`Error loading annotations: ${error.message}`, true, "danger"));
  } finally {
    setIsLoading(false);
  }
}


export async function saveAnnotations(
  event: React.MouseEvent<HTMLButtonElement>,
  clusterInfo: ClusterInfo,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  fileName: string,
  flowFileName: string
): Promise<void> {

  event.preventDefault();

  try {
    setIsLoading(true);
    if (fileName === "" || !fileName.includes(".csv")) {
      throw new Error(`${fileName} is an invalid file name, must be a .csv file`);
    }
    const urlSuffix = "/manual_gate_save_annotations";
    logger.info(
      "saveAnnotations",
      "annotation",
      "Saving annotations: ",
      urlSuffix
    )();
    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("flowFileName", flowFileName);
    formData.append("clusterInfo", JSON.stringify(clusterInfo));
    formData.append("firestoreField", "annotations_file");
    formData.append("firestoreDocument", `flow_data/${flowFileName}`);
      
    const responseData = await apiPost(urlSuffix, formData, "blob");
    const blob = new Blob([responseData]);
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    logger.error(
      "saveAnnotations",
      "error saving annotations: ",
      error
    )();
  } finally {
    setIsLoading(false);
  }
}

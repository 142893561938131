import * as d3 from 'd3';
import React, { useMemo } from "react";

import { findMax, findMin } from "../../utils/Utils";

const TICK_LENGTH = 10;

/**
 * Margins of the plot in pixels.
 */
export class Margin {
  top: number;
  bottom: number;
  left: number;
  right: number;

  constructor(top = 60, bottom = 60, left = 60, right = 60) {
    this.top = top;
    this.bottom = bottom;
    this.left = left;
    this.right = right;
  }
}


export function scaleBottom(
  x: Array<number>, boundsWidth: number
): d3.ScaleLinear<number, number> {
  const minimumX = findMin(x);
  const maximumX = findMax(x);
  const offset = (maximumX - minimumX) * 0.05;
  const xScale = d3.scaleLinear().domain(
    [minimumX - offset, maximumX + offset]
  ).range([0, boundsWidth]);
  return xScale;
}


export function scaleLeft(
  y: Array<number>, boundsHeight: number
): d3.ScaleLinear<number, number> {
  const minimumY = findMin(y);
  const maximumY = findMax(y);
  const offset = (maximumY - minimumY) * 0.05;
  const yScale = d3.scaleLinear().domain(
    [minimumY - offset, maximumY + offset]
  ).range([boundsHeight, 0]);
  return yScale;
}


/**
 * The data types for the AxisLeft component.
 *
 * @interface AxisLeftProps
 */
interface AxisLeftProps {
  /** The scale for the y-axis. */
  yScale: d3.ScaleLinear<number, number>;
  /** The number of pixels per tick on the axis. */
  pixelsPerTick: number;
  /** The width of the plot in pixels, excluding the margins. */
  boundsWidth: number;
}

export function AxisLeft(
  { yScale, pixelsPerTick, boundsWidth }: AxisLeftProps
): React.ReactElement {
  const range = yScale.range();

  const ticks = useMemo(() => {
    const height = range[0] - range[1];
    const numberOfTicksTarget = Math.floor(height / pixelsPerTick);

    return yScale.ticks(numberOfTicksTarget).map((value) => ({
      value: value,
      yOffset: yScale(value),
    }));
  }, [yScale]);

  return (
    <>
      {ticks.map(({ value, yOffset }) => (
        <g key={value} transform={`translate(0, ${yOffset})`} shapeRendering={"crispEdges"}>
          <line
            x1={-TICK_LENGTH}
            x2={boundsWidth + TICK_LENGTH}
            stroke="#D2D7D3"
            strokeWidth={0.5}
          />
          <text
            key={value}
            style={{
              fontSize: "10px",
              textAnchor: "middle",
              transform: "translateX(-30px)",
              fill: "#D2D7D3",
            }}
          >
            {value}
          </text>
        </g>
      ))}
    </>
  );
};


/**
 * The data types for the AxisBottom component.
 *
 * @interface AxisBottomProps
 */
interface AxisBottomProps {
  /** The scale for the x-axis. */
  xScale: d3.ScaleLinear<number, number>;
  /** The number of pixels per tick on the axis. */
  pixelsPerTick: number;
  /** The height of the plot in pixels, excluding the margins. */
  boundsHeight: number;
}

export function AxisBottom(
  { xScale, pixelsPerTick, boundsHeight }: AxisBottomProps
): React.ReactElement {
  const range = xScale.range();

  const ticks = useMemo(() => {
    const width = range[1] - range[0];
    const numberOfTicksTarget = Math.floor(width / pixelsPerTick);

    return xScale.ticks(numberOfTicksTarget).map((value) => ({
      value: value,
      xOffset: xScale(value),
    }));
  }, [xScale]);

  return (
    <>
      <g transform={`translate(0, ${boundsHeight})`}>
        {/* Ticks and labels */}
        {ticks.map(({ value, xOffset }) => (
          <g
            key={value}
            transform={`translate(${xOffset}, 0)`}
            shapeRendering={"crispEdges"}
          >
            <line
              y1={TICK_LENGTH}
              y2={-boundsHeight - TICK_LENGTH}
              stroke="#D2D7D3"
              strokeWidth={0.5}
            />
            <text
              key={value}
              style={{
                fontSize: "10px",
                textAnchor: "middle",
                transform: "translateY(20px)",
                fill: "#D2D7D3",
              }}
            >
              {value}
            </text>
          </g>
        ))}
      </g>
    </>
  );
};

interface PlotTitleProps {
  title: string;
}

export function PlotTitle(
  { title }: PlotTitleProps
): React.ReactElement {
  return (
    <>
      <h6 className="plot-title">
        {title}
      </h6>
    </>
  );
}


interface AxisTitleProps {
  title: string;
}

export function AxisTitle(
  { title }: AxisTitleProps
): React.ReactElement {
  return (
    <>
      <text
        x={-10} y={-25} dx="1em">
        {title}
      </text>
    </>
  );
}


interface AxisLeftLabelProps {
  yLabel: string;
  height: number;
  margin: Margin;
}

export function AxisLeftLabel(
  { yLabel, height, margin }: AxisLeftLabelProps
): React.ReactElement {

  const boundsHeight = height - margin.top - margin.bottom;

  return (
    <>
      <text
        transform={"rotate(-90)"}
        x={0 - boundsHeight / 2} y={0 - margin.left} dy="1em">
        {yLabel}
      </text>
    </>
  );
}


interface AxisBottomLabelProps {
  xLabel: string;
  width: number;
  height: number;
  margin: Margin;
}

export function AxisBottomLabel(
  { xLabel, width, height, margin }: AxisBottomLabelProps
): React.ReactElement {

  const boundsWidth = width - margin.left - margin.right;

  return (
    <>
      <text
        x={boundsWidth / 3} y={height - margin.bottom - 10} dx="1em">
        {xLabel}
      </text>
    </>
  );
}


interface AxesProps {
  width: number;
  height: number;
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  margin: Margin;
  xLabel?: string | undefined;
  yLabel?: string | undefined;
  title?: string | undefined;
}

export function Axes(
  { width, height, xScale, yScale, margin, xLabel, yLabel, title }: AxesProps
): React.ReactElement {

  const boundsWidth = width - margin.left - margin.right;
  const boundsHeight = height - margin.top - margin.bottom;

  return (
    <>
      <svg width={width} height={height}>
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[margin.left, margin.top].join(',')})`}
        >
          {/* title */}
          {title !== undefined &&
            <AxisTitle title={title} />
          }
          {/* y-axis */}
          <AxisLeft yScale={yScale} pixelsPerTick={30} boundsWidth={boundsWidth} />
          {yLabel !== undefined &&
            <AxisLeftLabel yLabel={yLabel} height={height} margin={margin} />
          }
          {/* x-axis */}
          <AxisBottom xScale={xScale} pixelsPerTick={30} boundsHeight={boundsHeight} />
          {xLabel !== undefined &&
            <AxisBottomLabel xLabel={xLabel} width={width} height={height} margin={margin} />
          }
        </g>
      </svg>
    </>
  );
}


